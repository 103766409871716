import {Reducer} from "redux";
import {FileActionTypes, FilesState} from "./types";

const initialState: FilesState = {
    data: [],
    errors: undefined,
    loading: false,
}
export const filesReducer: Reducer<FilesState> = (state = initialState, action) => {
    switch (action.type) {
        case FileActionTypes.UPLOAD_FILE_REQUESTED:
        case FileActionTypes.ALL_FILES_REQUESTED:
        case FileActionTypes.UPDATED_FILE_REQUESTED:
        case FileActionTypes.DELETED_FILE_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case FileActionTypes.UPLOAD_FILE_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case FileActionTypes.ALL_FILES_SUCCEEDED:
            return {...state, loading: false, data: action.payload, errors: undefined}

        case FileActionTypes.UPDATED_FILE_SUCCEEDED:
            return {...state, loading: false, data: state.data.map(file => file.id === action.payload.id ? action.payload : file)}

        case FileActionTypes.DELETED_FILE_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(file => file.id !== action.payload)}

        case FileActionTypes.FILES_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}