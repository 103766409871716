import {Reducer} from "redux";
import {PermissionsActionTypes, PermissionsState} from "./types";

const initialState: PermissionsState = {
    data: [],
    errors: undefined,
    loading: false,
}

export const permissionsReducer: Reducer<PermissionsState> = (state = initialState, action) => {
    switch (action.type) {
        case PermissionsActionTypes.ADD_PERMISSION_REQUESTED:
        case PermissionsActionTypes.ALL_PERMISSIONS_REQUESTED:
        case PermissionsActionTypes.ADD_USER_REQUESTED:
        case PermissionsActionTypes.REMOVE_USER_REQUESTED:
        case PermissionsActionTypes.ADD_EVENT_REQUESTED:
        case PermissionsActionTypes.REMOVE_EVENT_REQUESTED:
        case PermissionsActionTypes.ADD_FILE_REQUESTED:
        case PermissionsActionTypes.REMOVE_FILE_REQUESTED:
        case PermissionsActionTypes.UPDATED_PERMISSION_REQUESTED:
        case PermissionsActionTypes.DELETED_PERMISSION_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case PermissionsActionTypes.ADD_PERMISSION_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case PermissionsActionTypes.ALL_PERMISSIONS_SUCCEEDED:
            return {...state, loading: false, data: action.payload, errors: undefined}

        case PermissionsActionTypes.ADD_USER_SUCCEEDED:
        case PermissionsActionTypes.REMOVE_USER_SUCCEEDED:
        case PermissionsActionTypes.ADD_EVENT_SUCCEEDED:
        case PermissionsActionTypes.REMOVE_EVENT_SUCCEEDED:
        case PermissionsActionTypes.ADD_FILE_SUCCEEDED:
        case PermissionsActionTypes.REMOVE_FILE_SUCCEEDED:
        case PermissionsActionTypes.UPDATED_PERMISSION_SUCCEEDED:
            return {...state, loading: false, data: state.data.map(permission => permission.id === action.payload.id ? action.payload : permission)}

        case PermissionsActionTypes.DELETED_PERMISSION_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(permission => permission.id !== action.payload)}

        case PermissionsActionTypes.PERMISSIONS_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}