export class ApiBase {
    static base_url = process.env.REACT_APP_API_URL

    private static async baseRequest(method: string, path: string, token: string, body: any = undefined) {
        const configs = this.getRequestConfigs(method, token, body)
        const response = await fetch(`${this.base_url}${path}`, configs)
        return response.json()
    }

    private static getRequestConfigs(method: string, token: string, body: any = undefined): RequestInit {
        const configs: RequestInit = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }

        if (body !== undefined) {
            configs.body = JSON.stringify(body);
        }

        return configs
    }

    protected static async getRequest(path: string, token: string) {
        return this.baseRequest('GET', path, token)
    }

    protected static async postRequest(path: string, body: any, token: string) {
        return this.baseRequest('POST', path, token, body)
    }

    protected static async putRequest(path: string, body: any, token: string) {
        return this.baseRequest('PUT', path, token, body)
    }

    protected static async deleteRequest(path: string, token: string) {
        return this.baseRequest('DELETE', path, token)
    }
}