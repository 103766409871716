import {action} from "typesafe-actions";
import {EventsActionTypes} from "./types";
import {AddParticipantsToEventInput, CreateEventInput, Event, RemoveParticipantFromEventInput} from "../../api/events";

export const addEventRequested = (eventData: CreateEventInput, token: string) => action(EventsActionTypes.ADD_EVENT_REQUESTED, {eventData, token})
export const addEventSucceeded = (event: Event) => action(EventsActionTypes.ADD_EVENT_SUCCEEDED, event)
export const allEventsRequested = (token: string) => action(EventsActionTypes.ALL_EVENTS_REQUESTED, token)
export const allEventsSucceeded = (events: Event[]) => action(EventsActionTypes.ALL_EVENTS_SUCCEEDED, events)
export const addParticipantsRequested = (participants: AddParticipantsToEventInput, token: string) => action(EventsActionTypes.ADD_PARTICIPANTS_REQUESTED, {participants, token})
export const addParticipantsSucceeded = (event: Event) => action(EventsActionTypes.ADD_PARTICIPANTS_SUCCEEDED, event)
export const removeParticipantRequested = (participant: RemoveParticipantFromEventInput, token: string) => action(EventsActionTypes.REMOVE_PARTICIPANT_REQUESTED, {participant, token})
export const removeParticipantSucceeded = (event: Event) => action(EventsActionTypes.REMOVE_PARTICIPANT_SUCCEEDED, event)
export const updatedEventRequested = (event: Event, token: string) => action(EventsActionTypes.UPDATED_EVENT_REQUESTED, {event, token})
export const updatedEventSucceeded = (event: Event) => action(EventsActionTypes.UPDATED_EVENT_SUCCEEDED, event)
export const deletedEventRequested = (eventId: number, token: string) => action(EventsActionTypes.DELETED_EVENT_REQUESTED, {eventId, token})
export const deletedEventSucceeded = (eventId: number) => action(EventsActionTypes.DELETED_EVENT_SUCCEEDED, eventId)
export const eventsRequestFailed = (message: string) => action(EventsActionTypes.EVENTS_REQUEST_FAILED, message)
