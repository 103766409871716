import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import Selection from "../common/select";
import {CreateEventInput, Event, EventBookingStatus, EventCategory} from "../../../api/events";
import {dispatchAction} from "../../common";
import {addEventRequested} from "../../../store/events/action";
import {useDispatch, useStore} from "react-redux";
import {infosRequestFailed} from "../../../store/index-content/actions";
import {closeCreateEventModalRequested} from "../../../store/modals/actions";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import "react-datepicker/dist/react-datepicker.css";
import {EventBookingStatusMapping} from "../cards/events-card";


const CreateEventModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [events, setEvents] = useState<Event[]>(store.getState().events.data)
    const [showCreateModal, setShowCreateModal] = useState(store.getState().modals.createEventModal)

    const createEventDataInitialState: CreateEventInput = {
        title: '',
        subtitle: '',
        where: '',
        when: '',
        category: EventCategory.MBSR,
        booking_status: EventBookingStatus.SEATS_AVAILABLE,
        is_active: true,
        position: events.length,
        event_annotations: [],
        user_ids: [],
        permission_ids: [],
    }
    const [createEventData, setCreateEventData] = useState<CreateEventInput>(createEventDataInitialState)

    const dispatchError = () => dispatch(infosRequestFailed('Nicht Angemeldet'))
    const addEvent = () => dispatchAction(token, token => dispatch(addEventRequested({...createEventData, position: events.length}, token)), dispatchError)

    const closeCreateModal = () => dispatch(closeCreateEventModalRequested())

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            setEvents(state.events.data)
            setShowCreateModal(state.modals.createEventModal)
        })
    }, [store])

    return (
        <Modal show={showCreateModal} onHide={closeCreateModal}
               centered={true} className="create-event-modal">
            <Modal.Header closeButton>
                <Modal.Title>Neuen Kurs erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={2}>Titel</Col>
                        <Col md={10}>
                            <input
                                value={createEventData.title}
                                onChange={event => setCreateEventData(prevState => ({...prevState, title: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Untertitel</Col>
                        <Col md={10}>
                            <input
                                value={createEventData.subtitle}
                                onChange={event => setCreateEventData(prevState => ({...prevState, subtitle: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Wann</Col>
                        <Col md={10}>
                            <textarea
                                value={createEventData.when}
                                onChange={event => setCreateEventData(prevState => ({...prevState, when: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Wo</Col>
                        <Col md={10}>
                            <textarea
                                value={createEventData.where}
                                onChange={event => setCreateEventData(prevState => ({...prevState, where: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Kategorie</Col>
                        <Col md={10}>
                            <Selection
                                items={Object.values(EventCategory).map(category => ({
                                    label: category,
                                    value: category
                                }))}
                                selectedItem={{label: createEventData.category, value: createEventData.category}}
                                setSelectedItem={item => {
                                    if (EventCategory.hasOwnProperty(item.value)) {
                                        setCreateEventData(prevState => ({...prevState, category: item.value as EventCategory}))
                                    }
                                }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Buchungsstatus</Col>
                        <Col md={10}>
                            <Selection
                                items={Object.values(EventBookingStatus).map(status => ({
                                    label: EventBookingStatusMapping[status as keyof typeof EventBookingStatusMapping],
                                    value: status
                                }))}
                                selectedItem={{
                                    label: EventBookingStatusMapping[createEventData.booking_status as keyof typeof EventBookingStatusMapping],
                                    value: createEventData.booking_status
                                }}
                                setSelectedItem={item => {
                                    if (EventBookingStatus.hasOwnProperty(item.value)) {
                                        setCreateEventData(prevState => ({...prevState, booking_status: item.value as EventBookingStatus}))
                                    }
                                }}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeCreateModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    addEvent()
                    closeCreateModal()
                    setCreateEventData(createEventDataInitialState)
                }}>
                    Erstellen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateEventModal