import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './navigation.css';

const Navigation: React.FC = () => {
    const {pathname} = useLocation()

    const navItem = (name: string, link: string) => {
        return (
            <li className={pathname === link ? 'active' : ''}>
                <Link to={link}>{name}</Link>
            </li>
        )
    }

    return (
        <nav>
            <ul>
                {navItem('Startseite', '/startseite')}
                {navItem('Nutzer', '/user')}
                {navItem('Kurse', '/kurse')}
                {navItem('Dateien', '/dateien')}
                {navItem('Berechtigungen', '/berechtigungen')}
            </ul>
        </nav>
    );
}

export default Navigation;