import React, {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCog} from "@fortawesome/free-solid-svg-icons";
import {dispatchAction} from "../../common";
import {User} from "../../../api/user";
import {allUsersRequested, deletedUserRequested, updatedUserRequested, usersRequestFailed} from "../../../store/users/action";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import AddUserModal from "../modals/add-user-modal";
import {openAddUserModalRequested} from "../../../store/modals/actions";
import {askBefore} from "../common/helpers";


interface IProps {
    token: string | undefined
}

const UsersCard: React.FC<IProps> = ({token}) => {
    const store = useStore()
    const dispatch = useDispatch()

    const [users, setUsers] = useState<User[]>(store.getState().users.data)
    const [filteredUsers, setFilteredUsers] = useState<User[]>([])
    const [searchString, setSearchString] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(store.getState().users.loading)

    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            if (!state.users.loading) setUsers(state.users.data)
            setLoading(state.users.loading)
        })
    }, [store])

    useEffect(() => {
        if (!searchString) {
            setFilteredUsers(users)
        } else {
            setFilteredUsers(users.filter(user => user.forename.toLowerCase().includes(searchString.toLowerCase()) || user.surname.toLowerCase().includes(searchString.toLowerCase()) || user.email.toLowerCase().includes(searchString.toLowerCase())))
        }

    }, [searchString, users])

    useEffect(() => {
        if (token !== undefined) dispatch(allUsersRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(usersRequestFailed('Nicht Angemeldet'))

    const updateUser = (user: User) => dispatchAction(token, token => dispatch(updatedUserRequested(user, token)), dispatchError)
    const deleteUser = (userId: number) => {
        askBefore(
            "Willst du den Nutzer wirklich löschen?",
            () => dispatchAction(token, token => dispatch(deletedUserRequested(userId, token)), dispatchError)
        )
    }

    return <Card>
        <Card.Header as="h5">Nutzer</Card.Header>
        <Card.Body className="user-body">
            {
                loading && !filteredUsers
                    ? <p>loading</p>
                    : <div className="content-list">
                        <div className="new-user-box">
                            <div className={'search'}>
                                <label htmlFor="user-search">Suche: </label>
                                <input type="text" className={"search-input"} id={"user-search"} value={searchString} onChange={event => setSearchString(event.target.value)}/>
                            </div>
                            <Button className="create-user-button" onClick={() => dispatch(openAddUserModalRequested())}>
                                Neuer Nutzer
                            </Button>
                            <AddUserModal/>
                        </div>
                        <Container>
                            <Row>
                                <Col md={12} className="m-0 p-0 user-list">
                                    {
                                        users.length > 0
                                            ? <table>
                                                <tbody>
                                                {
                                                    filteredUsers.map(user => {
                                                        return (
                                                            <tr key={user.id} className="user-row hover-item">
                                                                <td width="80%">
                                                                    <input type="checkbox" checked={user.is_active} onChange={() => updateUser({...user, is_active: !user.is_active})}/>
                                                                    <span contentEditable={true} onBlur={event => updateUser({...user, forename: event.target.innerText})}>
                                                                        {user.forename}
                                                                    </span>
                                                                    &nbsp;
                                                                    <span contentEditable={true} onBlur={event => updateUser({...user, surname: event.target.innerText})}>
                                                                        {user.surname}
                                                                    </span>
                                                                    &nbsp;
                                                                    <span>
                                                                        ({user.email})
                                                                    </span>
                                                                    {user.is_admin ? <FontAwesomeIcon icon={faUserCog} className="admin-icon"/> : <></>}
                                                                </td>
                                                                <td width="10%" onClick={() => updateUser({...user, siezen: !user.siezen})}>
                                                                    <span>{user.siezen ? "Sie" : "Du"}</span>
                                                                </td>
                                                                <td width="10%" className="icon-column">
                                                                    <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deleteUser(user.id)}/>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            : <span className="no-data">keine Nutzer vorhanden</span>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
        </Card.Body>
    </Card>
}

export default UsersCard