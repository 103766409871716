import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {closeAddPermissionModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {addPermissionRequested, permissionsRequestFailed} from "../../../store/permissions/actions";
import {User} from "../../../api/user";
import DatePicker from "react-datepicker";
import {Event} from "../../../api/events";
import {File} from "../../../api/files";
import {allEventsRequested} from "../../../store/events/action";
import {allFilesRequested} from "../../../store/files/action";
import {allUsersRequested} from "../../../store/users/action";
import {getFileIcon} from "../common/helpers";
import {CreatePermissionInput} from "../../../api/permissions";
import {Option} from "react-multi-select-component/dist/types/lib/interfaces";
import {MultiSelect} from "react-multi-select-component";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const AddPermissionModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [users, setUsers] = useState<User[]>(store.getState().users.data)
    const [events, setEvents] = useState<Event[]>(store.getState().events.data)
    const [files, setFiles] = useState<File[]>(store.getState().files.data)
    const [showModal, setShowModal] = useState(store.getState().modals.addPermissionModal)

    const initialInputData: CreatePermissionInput = {title: '', is_active: true, file_ids: [], event_ids: [], user_ids: []}

    const [inputData, setInputData] = useState<CreatePermissionInput>(initialInputData)
    const [selectedUsers, setSelectedUsers] = useState<Option[]>([])
    const [selectedEvents, setSelectedEvents] = useState<Option[]>([])
    const [selectedFiles, setSelectedFiles] = useState<Option[]>([])

    const closeModal = () => dispatch(closeAddPermissionModalRequested())

    const dispatchError = () => dispatch(permissionsRequestFailed('Nicht Angemeldet'))
    const addPermission = () => dispatchAction(token, token => dispatch(addPermissionRequested({
        ...inputData,
        user_ids: selectedUsers.map(user => user.value),
        event_ids: selectedEvents.map(event => event.value),
        file_ids: selectedFiles.map(file => file.value),
    }, token)), dispatchError)

    const clearData = () => setInputData(initialInputData)

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        if (token !== undefined) dispatch(allEventsRequested(token))
        if (token !== undefined) dispatch(allFilesRequested(token))
        if (token !== undefined) dispatch(allUsersRequested(token))
        return store.subscribe(() => {
            const state = store.getState()
            setShowModal(state.modals.addPermissionModal)
            if (!state.events.loading) setEvents(state.events.data)
            if (!state.files.loading) setFiles(state.files.data)
            if (!state.users.loading) setUsers(state.users.data)
        })
    }, [dispatch, store, token])

    return (
        <Modal show={showModal} onHide={closeModal} centered={true} className="create-permission-modal">
            <Modal.Header closeButton>
                <Modal.Title>Neue Berechtigung erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={2}>Name</Col>
                        <Col md={10}>
                            <input type="text" value={inputData.title} onChange={event => setInputData(prevState => ({...prevState, title: event.target.value}))}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={2}>Gültigkeits-Zeitraum</Col>
                        <Col md={10} className={"permission-range"}>
                            <div className={"datepicker-input-box"}>
                                <DatePicker
                                    id="datepicker"
                                    value={inputData.valid_from?.toLocaleDateString('de')}
                                    onChange={(date: Date) => setInputData(prevState => ({...prevState, valid_from: date}))}
                                />
                                <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => setInputData(prevState => ({...prevState, valid_from: undefined}))}/>
                            </div>
                            <span className="separator"> - </span>
                            <div className={"datepicker-input-box"}>
                                <DatePicker
                                    id="datepicker"
                                    value={inputData.valid_until?.toLocaleDateString('de')}
                                    onChange={(date: Date) => setInputData(prevState => ({...prevState, valid_until: date}))}
                                />
                                <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => setInputData(prevState => ({...prevState, valid_until: undefined}))}/>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={2}>Dateien</Col>
                        <Col md={10}>
                            <MultiSelect
                                options={files.map(file => ({
                                    label: `${getFileIcon(file.type)} ${file.name}`,
                                    value: file.id
                                }))}
                                value={selectedFiles}
                                onChange={setSelectedFiles}
                                labelledBy="Wähle Dateien aus"
                            />
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={2}>Berechtigte Kurse</Col>
                        <Col md={10}>
                            <MultiSelect
                                options={events.map(event => ({
                                    label: `${event.title} - ${event.subtitle}`,
                                    value: event.id
                                }))}
                                value={selectedEvents}
                                onChange={setSelectedEvents}
                                labelledBy="(Optional) Kurse hinzufügen"
                            />
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={2}>Berechtigte User</Col>
                        <Col md={10}>
                            <MultiSelect
                                options={users.map(user => ({
                                    label: `${user.forename} ${user.surname} (${user.email})`,
                                    value: user.id
                                }))}
                                value={selectedUsers}
                                onChange={setSelectedUsers}
                                labelledBy="(Optional) User hinzufügen"
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    addPermission()
                    clearData()
                    closeModal()
                }}>
                    Erstellen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPermissionModal