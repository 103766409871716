import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {dispatchAction} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {allInfosRequested, deletedInfoRequested, infosRequestFailed, updatedInfoRequested} from "../../../store/index-content/actions";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {askBefore} from "../common/helpers";
import {openAddInfoModalRequested} from "../../../store/modals/actions";
import {InfoItem} from "../../../api/infos";
import AddInfoModal from "../modals/add-info-modal";

interface IProps {
    token: string | undefined
}

const InfosCard: React.FC<IProps> = ({token}) => {
    const store = useStore()
    const dispatch = useDispatch()

    const [dragId, setDragId] = useState<string | undefined>();
    const [infos, setInfos] = useState<InfoItem[]>(store.getState().infos.data)

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState()
            if (!state.infos.loading) setInfos(state.infos.data)
        })
        return () => unsubscribe()
    }, [store])

    useEffect(() => {
        if (token !== undefined) dispatch(allInfosRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(infosRequestFailed('Nicht Angemeldet'))

    const updateInfo = (info: InfoItem) => dispatchAction(token, token => dispatch(updatedInfoRequested(info, token)), dispatchError)
    const deleteInfo = (infoId: number) => {
        askBefore(
            'Willst die Info wirklich löschen?',
            () => dispatchAction(token, token => dispatch(deletedInfoRequested(infoId, token)), dispatchError)
        )
    }

    const handleDrag = (event: any) => setDragId(event.currentTarget.id)

    const handleDrop = (event: any) => {
        const dragInfo = infos.find(info => info.id.toString() === dragId)
        const dropInfo = infos.find(info => info.id.toString() === event.currentTarget.id)

        if (dragInfo === undefined || dropInfo === undefined) return

        infos.forEach(info => {
            if (info.id.toString() === dragId) {
                updateInfo({...info, position: dropInfo.position})
            }
            if (info.id.toString() === event.currentTarget.id) {
                updateInfo({...info, position: dragInfo.position})
            }
        })
    }

    return <Card>
        <Card.Header as="h5">Aktuelles</Card.Header>
        <Card.Body>
            <div className="content-list">
                <div className="add-icon-wrapper">
                    <FontAwesomeIcon icon={faPlus} focusable={true} className="add-icon" onClick={() => dispatch(openAddInfoModalRequested())}/>
                    <AddInfoModal/>
                </div>
                {infos.length > 0
                    ? <table>
                        <tbody>
                        {infos.map(info => {
                            return (
                                <tr key={info.id} id={info.id.toString()} className="hover-item" draggable={true} onDragOver={event => event.preventDefault()}
                                    onDragStart={handleDrag} onDrop={handleDrop}>
                                    <td width="100%" className="two-inputs">
                                        <span contentEditable={true} onBlur={event => updateInfo({...info, text: event.target.innerText})}>
                                            {info.text}
                                        </span>
                                    </td>
                                    <td width="5%" className="icon-column">
                                        <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deleteInfo(info.id)}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : <span className="no-data">keine Infos vorhanden</span>}
            </div>
        </Card.Body>
    </Card>
}

export default InfosCard