import React from "react";
import Lottie, {Options} from "react-lottie";
import loadingAnimation from '../../animations/infinity-loading.json'

const Loading: React.FC = () => {
    const options: Options = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    return <Lottie height={50} width={150} options={options}/>
}

export default Loading