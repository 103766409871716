import {call, takeEvery} from "redux-saga/effects";
import {getDeleteRequestHandler, getRequestHandler} from "../common";
import {FileActionTypes} from "./types";
import {
    allFilesRequested,
    allFilesSucceeded,
    deletedFileRequested,
    deletedFileSucceeded,
    filesRequestFailed,
    updatedFileRequested,
    updatedFileSucceeded,
    uploadFileRequested,
    uploadFileSucceeded
} from "./action";
import {ApiFiles} from "../../api/files";

export default function* watchFileRequests() {
    yield takeEvery(FileActionTypes.UPLOAD_FILE_REQUESTED, getRequestHandler<typeof uploadFileRequested>(
        uploadFileSucceeded, filesRequestFailed,
        (action) => call(ApiFiles.uploadFile, action.payload.fileData, action.payload.token)
    ))
    yield takeEvery(FileActionTypes.ALL_FILES_REQUESTED, getRequestHandler<typeof allFilesRequested>(
        allFilesSucceeded, filesRequestFailed,
        (action) => call(ApiFiles.getFiles, action.payload)
    ))
    yield takeEvery(FileActionTypes.UPDATED_FILE_REQUESTED, getRequestHandler<typeof updatedFileRequested>(
        updatedFileSucceeded, filesRequestFailed,
        (action) => call(ApiFiles.updateFile, action.payload.file, action.payload.token)
    ))
    yield takeEvery(FileActionTypes.DELETED_FILE_REQUESTED, getDeleteRequestHandler<typeof deletedFileRequested>(
        (action) => deletedFileSucceeded(action.payload.fileId),
        filesRequestFailed,
        (action) => call(ApiFiles.deleteFile, action.payload.fileId, action.payload.token)
    ))
}