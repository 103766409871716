import React, {useEffect, useState} from "react"
import {Card, Col, Container, Row} from "react-bootstrap"
import {useDispatch, useStore} from "react-redux"
import {loginRequested} from "../../store/admin/actions"
import {handleKeyPress} from "../common";
import Lottie from "react-lottie";
import loadingAnimation from '../../animations/fancy-loading.json'
import './login.css'

const Login: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({email: "", password: ""})

    const store = useStore()
    const dispatch = useDispatch()

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState()
            setLoading(state.admin.loading)
        })
        return () => unsubscribe()
    })

    const login = () => dispatch(loginRequested(formData.email, formData.password));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = event.target
        setFormData(prevState => ({...prevState, [id]: value}))
    }

    return <>
        <div className="grey-overlay"/>
        <div className={'login-popup'}>
            <Card>
                <Card.Header as="h5">Login</Card.Header>
                <Card.Body className={'login-body'}>
                    {loading
                        ? <Lottie height={250} width={400} options={{
                            loop: true,
                            autoplay: true,
                            animationData: loadingAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}/>
                        : <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} className={'login-field'}>
                                    <input
                                        id={'email'}
                                        placeholder={'Email'}
                                        alt={'email'}
                                        value={formData.email}
                                        onChange={handleChange}
                                        onKeyPress={(event => handleKeyPress(event, login))}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} className={'login-field'}>
                                    <input
                                        id={'password'}
                                        type={'password'}
                                        placeholder={'Passwort'}
                                        alt={'password'}
                                        value={formData.password}
                                        onChange={handleChange}
                                        onKeyPress={(event => handleKeyPress(event, login))}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} className={'login-button'}>
                                    <button className={'text-light'} onClick={login}>
                                        Anmelden
                                    </button>
                                </Col>
                            </Row>
                        </Container>}
                </Card.Body>
            </Card>
        </div>
    </>
}

export default Login;
