import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Skeleton from "./pages/skeleton";
import {configureStore} from "./store/root";
import 'bootstrap/dist/css/bootstrap.min.css'
import './base.css';

const store = configureStore()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Skeleton/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);
