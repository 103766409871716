import {action} from "typesafe-actions";
import {PermissionsActionTypes} from "./types";
import {AddOrRemoveEventToPermissionInput, AddOrRemoveFileToPermissionInput, AddOrRemoveUserToPermissionInput, CreatePermissionInput, Permission} from "../../api/permissions";


export const addPermissionRequested = (permissionData: CreatePermissionInput, token: string) => action(PermissionsActionTypes.ADD_PERMISSION_REQUESTED, {permissionData, token})
export const addPermissionSucceeded = (permission: Permission) => action(PermissionsActionTypes.ADD_PERMISSION_SUCCEEDED, permission)
export const allPermissionsRequested = (token: string) => action(PermissionsActionTypes.ALL_PERMISSIONS_REQUESTED, token)
export const allPermissionsSucceeded = (permissions: Permission[]) => action(PermissionsActionTypes.ALL_PERMISSIONS_SUCCEEDED, permissions)
export const addUserRequested = (data: AddOrRemoveUserToPermissionInput, token: string) => action(PermissionsActionTypes.ADD_USER_REQUESTED, {data, token})
export const addUserSucceeded = (permission: Permission) => action(PermissionsActionTypes.ADD_USER_SUCCEEDED, permission)
export const removeUserRequested = (data: AddOrRemoveUserToPermissionInput, token: string) => action(PermissionsActionTypes.REMOVE_USER_REQUESTED, {data, token})
export const removeUserSucceeded = (permission: Permission) => action(PermissionsActionTypes.REMOVE_USER_SUCCEEDED, permission)
export const addEventRequested = (data: AddOrRemoveEventToPermissionInput, token: string) => action(PermissionsActionTypes.ADD_EVENT_REQUESTED, {data, token})
export const addEventSucceeded = (permission: Permission) => action(PermissionsActionTypes.ADD_EVENT_SUCCEEDED, permission)
export const removeEventRequested = (data: AddOrRemoveEventToPermissionInput, token: string) => action(PermissionsActionTypes.REMOVE_EVENT_REQUESTED, {data, token})
export const removeEventSucceeded = (permission: Permission) => action(PermissionsActionTypes.REMOVE_EVENT_SUCCEEDED, permission)
export const addFileRequested = (data: AddOrRemoveFileToPermissionInput, token: string) => action(PermissionsActionTypes.ADD_FILE_REQUESTED, {data, token})
export const addFileSucceeded = (permission: Permission) => action(PermissionsActionTypes.ADD_FILE_SUCCEEDED, permission)
export const removeFileRequested = (data: AddOrRemoveFileToPermissionInput, token: string) => action(PermissionsActionTypes.REMOVE_FILE_REQUESTED, {data, token})
export const removeFileSucceeded = (permission: Permission) => action(PermissionsActionTypes.REMOVE_FILE_SUCCEEDED, permission)
export const updatedPermissionRequested = (permission: Permission, token: string) => action(PermissionsActionTypes.UPDATED_PERMISSION_REQUESTED, {permission, token})
export const updatedPermissionSucceeded = (permission: Permission) => action(PermissionsActionTypes.UPDATED_PERMISSION_SUCCEEDED, permission)
export const deletedPermissionRequested = (permissionId: number, token: string) => action(PermissionsActionTypes.DELETED_PERMISSION_REQUESTED, {permissionId, token})
export const deletedPermissionSucceeded = (permissionId: number) => action(PermissionsActionTypes.DELETED_PERMISSION_SUCCEEDED, permissionId)
export const permissionsRequestFailed = (message: string) => action(PermissionsActionTypes.PERMISSIONS_REQUEST_FAILED, message)
