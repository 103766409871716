import {ApiBase} from "./base";
import {Permission} from "./permissions";

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE',
}

export type User = {
    id: number
    forename: string
    surname: string
    email: string
    gender: Gender
    is_active: boolean
    is_admin: boolean
    created_at: Date
    last_login: Date
    siezen: boolean
    permissions?: Permission[]
    events?: Event[]
}

export type CreateUserInput = {
    email: string
    forename: string
    surname: string
    gender: Gender
    siezen: boolean
    is_active: boolean
}

export class ApiUsers extends ApiBase {
    public static async getUsers(token: string) {
        return ApiUsers.getRequest('/users/', token)
    }

    public static async getUser(userId: number, token: string) {
        return ApiUsers.getRequest(`/users/${userId}`, token)
    }

    public static async createUser(userInput: CreateUserInput, token: string) {
        return ApiUsers.postRequest('/users/', userInput, token)
    }

    public static async updateUser(user: User, token: string) {
        return ApiUsers.putRequest(`/users/${user.id}`, user, token)
    }

    public static async deleteUser(userId: number, token: string) {
        return ApiUsers.deleteRequest(`/users/${userId}`, token)
    }
}
