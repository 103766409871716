import React from "react";
import chroma from "chroma-js";
import Select from "react-select";

export type SelectionItem = {
    label: string;
    value: string;
}

type ConfigOption = {
    data: SelectionItem;
    isDisabled: boolean;
    isFocused: boolean;
    isSelected: boolean;
}

export interface IProps {
    items: SelectionItem[]
    selectedItem: SelectionItem | null
    setSelectedItem: (item: SelectionItem) => any;
}

const Selection: React.FC<IProps> = ({items, selectedItem, setSelectedItem}) => {
    const LIST_ITEM_COLOR = '#E84545'

    const setItem = (item: SelectionItem | null) => {
        if (item !== null) {
            setSelectedItem(item);
        }
    }

    const stylesConfig = {
        control: (styles: any) => ({...styles, backgroundColor: 'white'}),
        option: (styles: any, {data, isDisabled, isFocused, isSelected}: ConfigOption) => {
            const color = chroma(LIST_ITEM_COLOR);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? color.css()
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? 'white'
                        : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? color.css() : color.alpha(0.3).css()),
                },
            };
        },
    }

    return <Select
        styles={stylesConfig}
        theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
                ...theme.colors,
                primary: LIST_ITEM_COLOR,
            },
        })}
        options={items}
        value={selectedItem}
        onChange={setItem}
    />;
};

export default Selection