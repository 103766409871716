import React, {useState} from 'react';
import {useStore} from "react-redux";
import ReferencesCard from "../../components/content/cards/references-card";
import {useSetTokenEffect} from "./hooks";
import InfosCard from "../../components/content/cards/infos-card";
import TipsCard from "../../components/content/cards/tips-card";


const LandingPage: React.FC = () => {
    const store = useStore()
    const [token, setToken] = useState<string>()

    useSetTokenEffect(store, setToken)

    return <>
        <InfosCard token={token}/>
        <TipsCard token={token}/>
        <ReferencesCard token={token}/>
    </>;
}

export default LandingPage;