import React, {useEffect, useState} from "react";
import {Accordion, Button, Card, Col, Container, Row} from "react-bootstrap";
import {CreateEventInput, Event, EventBookingStatus, EventCategory} from "../../../api/events";
import {useDispatch, useStore} from "react-redux";
import {addEventRequested, allEventsRequested, deletedEventRequested, eventsRequestFailed, removeParticipantRequested, updatedEventRequested} from "../../../store/events/action";
import CreateEventModal from "../modals/create-event-modal";
import {openAddAnnotationModalRequested, openAddParticipantModalRequested, openCreateEventModalRequested, openUpdateEventModalRequested} from "../../../store/modals/actions";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {dispatchAction} from "../../common";
import UpdateEventModal from "../modals/update-event-modal";
import AddUserToEventModal from "../modals/add-participant-modal";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {askBefore} from "../common/helpers";
import AddAnnotationToEventModal from "../modals/add-annotation-modal";
import Selection from "../common/select";


export enum EventBookingStatusMapping {
    SEATS_AVAILABLE = 'freie Plätze verfügbar',
    LIMITED_CAPACITY = 'nur noch wenige Plätze verfügbar',
    BOOKED_UP = 'ausgebucht',
}

const EventsCard: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [dragId, setDragId] = useState<string | undefined>();
    const [events, setEvents] = useState<Event[]>(store.getState().events.data)
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([])
    const [searchString, setSearchString] = useState<string>('')
    const [filteredCategory, setFilteredCategory] = useState<EventCategory | null>()

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            if (!state.events.loading) setEvents(state.events.data)
        })
    }, [store])

    useEffect(() => {
        if (!searchString) {
            setFilteredEvents(events)
        } else {
            setFilteredEvents(events.filter(event => event.title.toLowerCase().includes(searchString.toLowerCase()) || event.subtitle.toLowerCase().includes(searchString.toLowerCase())))
        }
    }, [searchString, events])

    useEffect(() => {
        if (!filteredCategory) {
            setFilteredEvents(events)
        } else {
            setFilteredEvents(events.filter(event => event.category === filteredCategory))
        }
    }, [filteredCategory, events])

    useEffect(() => {
        if (token !== undefined) dispatch(allEventsRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(eventsRequestFailed('Nicht Angemeldet'))
    const updateEvent = (event: Event) => dispatchAction(token, token => dispatch(updatedEventRequested(event, token)), dispatchError)
    const deleteEvent = (eventId: number) => {
        askBefore(
            "Willst du den Kurs wirklich löschen?",
            () => dispatchAction(token, token => dispatch(deletedEventRequested(eventId, token)), dispatchError)
        )
    }
    const removeAnnotation = (event: Event, annotation: string) => {
        askBefore(
            "Willst du die Anmerkung wirklich entfernen?",
            () => updateEvent({...event, annotations: event.annotations.filter(eventAnnotation => annotation !== eventAnnotation)})
        )
    }
    const removeUser = (eventId: number, userId: number) => {
        askBefore(
            "Willst du den Nutzer wirklich von dem Kurs entfernen?",
            () => dispatchAction(token, token => dispatch(removeParticipantRequested({eventId, userId}, token)), dispatchError)
        )
    }

    const handleDrag = (event: any) => setDragId(event.currentTarget.id)

    const handleDrop = (event: any) => {
        const dragEvent = events.find(item => item.id.toString() === dragId)
        const dropEvent = events.find(item => item.id.toString() === event.currentTarget.id)

        if (dragEvent === undefined || dropEvent === undefined) return

        events.forEach(item => {
            if (item.id.toString() === dragId) {
                updateEvent({...item, position: dropEvent.position})
            }
            if (item.id.toString() === event.currentTarget.id) {
                updateEvent({...item, position: dragEvent.position})
            }
        })
    }

    const duplicateEvent = (event: Event) => {
        const duplicatedEvent: CreateEventInput = {
            title: `${event.title} Kopie`,
            subtitle: event.subtitle,
            when: event.when,
            where: event.where,
            category: event.category,
            booking_status: EventBookingStatus.SEATS_AVAILABLE,
            is_active: false,
            position: events.length,
            event_annotations: event.annotations,
            user_ids: [],
            permission_ids: [],
        }
        dispatchAction(token, token => dispatch(addEventRequested(duplicatedEvent, token)), dispatchError)
    }

    return <Card>
        <Card.Header as="h5">Kurse</Card.Header>
        <Card.Body>
            <div className="content-list">
                <div className="create-event-button">
                    <div className={'search'}>
                        <label htmlFor="event-search">Suche: </label>
                        <input type="text" className={"search-input"} id={"event-search"} value={searchString} onChange={event => setSearchString(event.target.value)}/>
                    </div>
                    <div className={'filter-category'}>
                        <Selection
                            items={[
                                ...Object.values(EventCategory).map(category => ({
                                    label: category,
                                    value: category
                                })),
                                {label: 'ALLE', value: 'all'}
                            ]}
                            selectedItem={filteredCategory ? {label: filteredCategory, value: filteredCategory} : {label: 'ALLE', value: 'all'}}
                            setSelectedItem={item => {
                                if (item.value === 'all') {
                                    setFilteredCategory(null)
                                } else if (EventCategory.hasOwnProperty(item.value)) {
                                    setFilteredCategory(item.value as EventCategory)
                                }
                            }}/>
                    </div>
                    <Button onClick={() => dispatch(openCreateEventModalRequested())}>
                        Neuer Kurs
                    </Button>
                    <CreateEventModal/>
                    <UpdateEventModal/>
                    <AddAnnotationToEventModal/>
                    <AddUserToEventModal/>
                </div>
                {filteredEvents.length > 0
                    ? <Accordion className="event-list-item">
                        {filteredEvents.map(event => {
                            return <Card key={event.id} id={event.id.toString()} draggable={true} onDragOver={(ev: any) => ev.preventDefault()} onDragStart={handleDrag} onDrop={handleDrop}>
                                <Accordion.Toggle as={Card.Header} eventKey={event.id.toString()}>
                                    <div>
                                        <input type="checkbox" checked={event.is_active} onChange={() => updateEvent({...event, is_active: !event.is_active})}/>
                                        <span className="title">{event.title}</span>
                                        {event.subtitle ? <span className="subtitle"> - {event.subtitle}</span> : <></>}
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faCopy} focusable={true} className="edit-icon" onClick={() => duplicateEvent(event)}/>
                                        <FontAwesomeIcon icon={faPencilAlt} focusable={true} className="edit-icon" onClick={() => dispatch(openUpdateEventModalRequested(event))}/>
                                        <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deleteEvent(event.id)}/>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={event.id.toString()}>
                                    <Card.Body>
                                        <Container className="event-details">
                                            <Row>
                                                <Col md={4}>Wann?</Col>
                                                <Col md={8} className="category" style={{whiteSpace: 'pre'}}>{event.when}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>Wo?</Col>
                                                <Col md={8} className="category" style={{whiteSpace: 'pre'}}>{event.where}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>Kategorie</Col>
                                                <Col md={8} className="category">{event.category}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>Buchungsstatus</Col>
                                                <Col md={8}
                                                     className="category">{EventBookingStatusMapping[event.status]}</Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col md={4}>Anmerkungen</Col>
                                                <Col md={8} className="annotations">
                                                    <div className="icon-wrapper">
                                                        <FontAwesomeIcon icon={faPlus} focusable={true} className="add-icon" onClick={() => dispatch(openAddAnnotationModalRequested(event))}/>
                                                    </div>
                                                    {event.annotations?.length > 0
                                                        ? <ul>
                                                            {
                                                                event.annotations.map(annotation => {
                                                                    return (
                                                                        <li key={annotation} className={"editable-annotation"}>
                                                                            <div contentEditable={true} onBlur={e => updateEvent({
                                                                                ...event,
                                                                                annotations: event.annotations.map(ann => ann === annotation ? e.target.innerText : ann)
                                                                            })}>
                                                                                {annotation}
                                                                            </div>
                                                                            <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon"
                                                                                             onClick={() => removeAnnotation(event, annotation)}/>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                        : <span>keine Anmerkungen eingetragen</span>
                                                    }
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col md={4}>Teilnehmer</Col>
                                                <Col md={8} className="enrolled-users">
                                                    <div className="icon-wrapper">
                                                        <FontAwesomeIcon icon={faPlus} focusable={true} className="add-icon" onClick={() => dispatch(openAddParticipantModalRequested(event))}/>
                                                    </div>
                                                    {
                                                        event.users && event.users.length > 0
                                                            ? <ul>
                                                                {
                                                                    event.users.map(user => {
                                                                        return (
                                                                            <li key={user.id}>
                                                                                {user.forename} {user.surname} ({user.email})
                                                                                <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => removeUser(event.id, user.id)}/>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            : <span>keine Teilnehmer eingetragen</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        })}
                    </Accordion>
                    : <span className="no-data">keine Kurse vorhanden</span>}
            </div>
        </Card.Body>
    </Card>
}

export default EventsCard