import {Reducer} from 'redux'
import {AdminActionTypes, AdminState} from "./types";

const initialState: AdminState = {
    data: null,
    errors: undefined,
    loading: false
}

export const adminReducer: Reducer<AdminState> = (state = initialState, action) => {
    switch (action.type) {
        case AdminActionTypes.LOGIN_REQUEST: {
            return {...state, loading: true, errors: undefined}
        }
        case AdminActionTypes.LOGIN_SUCCESS: {
            localStorage.setItem('accessToken', action.payload.access_token)
            return {...state, loading: false, data: action.payload, errors: undefined}
        }
        case AdminActionTypes.LOGIN_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        case AdminActionTypes.LOGOUT: {
            return {...state, ...initialState}
        }
        default: {
            return state
        }
    }
}