import {dispatchAction} from "../../common";
import React, {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {closeAddUserModalRequested} from "../../../store/modals/actions";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {CreateUserInput, Gender} from "../../../api/user";
import {addUserRequested, usersRequestFailed} from "../../../store/users/action";
import Selection from "../common/select";


const AddUserModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const initialInputData: CreateUserInput = {email: '', forename: '', surname: '', gender: Gender.MALE, siezen: false, is_active: true}

    const [token, setToken] = useState<string>()
    const [inputData, setInputData] = useState<CreateUserInput>(initialInputData)
    const [showModal, setShowModal] = useState(store.getState().modals.addUserModal)

    const closeModal = () => {
        setInputData(initialInputData)
        dispatch(closeAddUserModalRequested())
    }

    const dispatchError = () => dispatch(usersRequestFailed('Nicht Angemeldet'))
    const addUser = () => dispatchAction(token, token => dispatch(addUserRequested(inputData, token)), dispatchError)

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            setShowModal(state.modals.addUserModal)
        })
    }, [dispatch, store, token])

    return <Modal show={showModal} onHide={closeModal} centered={true} className="create-user-modal">
        <Modal.Header closeButton>
            <Modal.Title>Nutzer hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Container>
                    <Row>
                        <Col md={4}>Email</Col>
                        <Col md={8}>
                            <input
                                value={inputData.email}
                                onChange={event => setInputData(prevState => ({...prevState, email: event.target.value}))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>Vorname</Col>
                        <Col md={8}>
                            <input
                                value={inputData.forename}
                                onChange={event => setInputData(prevState => ({...prevState, forename: event.target.value}))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>Nachname</Col>
                        <Col md={8}>
                            <input
                                value={inputData.surname}
                                onChange={event => setInputData(prevState => ({...prevState, surname: event.target.value}))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>Geschlecht</Col>
                        <Col md={8}>
                            <Selection
                                items={Object.values(Gender).map(gender => ({
                                    label: gender,
                                    value: gender
                                }))}
                                selectedItem={{label: inputData.gender, value: inputData.gender}}
                                setSelectedItem={item => {
                                    if (Gender.hasOwnProperty(item.value)) {
                                        setInputData(prevState => ({...prevState, gender: item.value as Gender}))
                                    }
                                }}/>
                        </Col>
                    </Row>
                    <Row className={"center"}>
                        <Col md={4}>Siezen</Col>
                        <Col md={8}>
                            <input type="checkbox" onChange={() => setInputData(prevState => ({...prevState, siezen: !prevState.siezen}))}/>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Schließen
            </Button>
            <Button variant="primary" onClick={() => {
                if (inputData.email === "" && inputData.forename === "" && inputData.surname === "")
                    return alert("Felder nicht vollständig ausgefüllt")
                addUser()
                closeModal()
            }}>
                Hinzufügen
            </Button>
        </Modal.Footer>
    </Modal>
}

export default AddUserModal