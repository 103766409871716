import {Reducer} from "redux";
import {Event} from "../../api/events"
import {EventsActionTypes, EventsState} from "./types";

const initialState: EventsState = {
    data: [],
    errors: undefined,
    loading: false,
}

export const eventsReducer: Reducer<EventsState> = (state = initialState, action) => {
    switch (action.type) {
        case EventsActionTypes.ADD_EVENT_REQUESTED:
        case EventsActionTypes.ALL_EVENTS_REQUESTED:
        case EventsActionTypes.ADD_PARTICIPANTS_REQUESTED:
        case EventsActionTypes.REMOVE_PARTICIPANT_REQUESTED:
        case EventsActionTypes.UPDATED_EVENT_REQUESTED:
        case EventsActionTypes.DELETED_EVENT_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case EventsActionTypes.ADD_EVENT_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case EventsActionTypes.ALL_EVENTS_SUCCEEDED:
            return {...state, loading: false, data: action.payload.sort((first: Event, second: Event) => first.position - second.position), errors: undefined}

        case EventsActionTypes.ADD_PARTICIPANTS_SUCCEEDED:
        case EventsActionTypes.REMOVE_PARTICIPANT_SUCCEEDED:
        case EventsActionTypes.UPDATED_EVENT_SUCCEEDED:
            const updatedEvents = state.data
                .map(event => event.id === action.payload.id ? action.payload : event)
                .sort((first, second) => first.position - second.position)
            return {...state, loading: false, data: updatedEvents}

        case EventsActionTypes.DELETED_EVENT_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(event => event.id !== action.payload)}

        case EventsActionTypes.EVENTS_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}