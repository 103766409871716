import {Event} from "../../api/events";
import {Permission} from "../../api/permissions";


export enum ModalActionTypes {
    OPEN_CREATE_EVENT_MODAL_REQUESTED = '@@modals/OPEN_CREATE_EVENT_MODAL_REQUESTED',
    CLOSE_CREATE_EVENT_MODAL_REQUESTED = '@@modals/CLOSE_CREATE_EVENT_MODAL_REQUESTED',

    OPEN_UPDATE_EVENT_MODAL_REQUESTED = '@@modals/OPEN_UPDATE_EVENT_MODAL_REQUESTED',
    CLOSE_UPDATE_EVENT_MODAL_REQUESTED = '@@modals/CLOSE_UPDATE_EVENT_MODAL_REQUESTED',

    OPEN_ADD_PARTICIPANT_MODAL_REQUESTED = '@@modals/OPEN_ADD_PARTICIPANT_MODAL_REQUESTED',
    CLOSE_ADD_PARTICIPANT_MODAL_REQUESTED = '@@modals/CLOSE_ADD_PARTICIPANT_MODAL_REQUESTED',

    OPEN_ADD_ANNOTATION_MODAL_REQUESTED = '@@modals/OPEN_ADD_ANNOTATION_MODAL_REQUESTED',
    CLOSE_ADD_ANNOTATION_MODAL_REQUESTED = '@@modals/CLOSE_ADD_ANNOTATION_MODAL_REQUESTED',

    OPEN_ADD_PERMISSION_MODAL_REQUESTED = '@@modals/OPEN_ADD_PERMISSION_MODAL_REQUESTED',
    CLOSE_ADD_PERMISSION_MODAL_REQUESTED = '@@modals/CLOSE_ADD_PERMISSION_MODAL_REQUESTED',

    OPEN_UPDATE_PERMISSION_MODAL_REQUESTED = '@@modals/OPEN_UPDATE_PERMISSION_MODAL_REQUESTED',
    CLOSE_UPDATE_PERMISSION_MODAL_REQUESTED = '@@modals/CLOSE_UPDATE_PERMISSION_MODAL_REQUESTED',

    OPEN_ADD_FILE_TO_PERMISSION_MODAL_REQUESTED = '@@modals/OPEN_ADD_RESOURCE_TO_PERMISSION_MODAL_REQUESTED',
    CLOSE_ADD_FILE_TO_PERMISSION_MODAL_REQUESTED = '@@modals/CLOSE_ADD_RESOURCE_TO_PERMISSION_MODAL_REQUESTED',

    OPEN_ADD_USER_TO_PERMISSION_MODAL_REQUESTED = '@@modals/OPEN_ADD_USER_TO_PERMISSION_MODAL_REQUESTED',
    CLOSE_ADD_USER_TO_PERMISSION_MODAL_REQUESTED = '@@modals/CLOSE_ADD_USER_TO_PERMISSION_MODAL_REQUESTED',

    OPEN_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED = '@@modals/OPEN_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED',
    CLOSE_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED = '@@modals/CLOSE_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED',

    OPEN_ADD_REFERENCE_MODAL_REQUESTED = '@@modals/OPEN_ADD_REFERENCE_MODAL_REQUESTED',
    CLOSE_ADD_REFERENCE_MODAL_REQUESTED = '@@modals/CLOSE_ADD_REFERENCE_MODAL_REQUESTED',

    OPEN_ADD_TIP_MODAL_REQUESTED = '@@modals/OPEN_ADD_TIP_MODAL_REQUESTED',
    CLOSE_ADD_TIP_MODAL_REQUESTED = '@@modals/CLOSE_ADD_TIP_MODAL_REQUESTED',

    OPEN_ADD_INFO_MODAL_REQUESTED = '@@modals/OPEN_ADD_INFO_MODAL_REQUESTED',
    CLOSE_ADD_INFO_MODAL_REQUESTED = '@@modals/CLOSE_ADD_INFO_MODAL_REQUESTED',

    OPEN_ADD_USER_MODAL_REQUESTED = '@@modals/OPEN_ADD_USER_MODAL_REQUESTED',
    CLOSE_ADD_USER_MODAL_REQUESTED = '@@modals/CLOSE_ADD_USER_MODAL_REQUESTED',
}

export interface ModalsState {
    createEventModal: boolean
    updateEventModal: {
        opened: boolean
        event?: Event
    }
    addParticipantModal: {
        opened: boolean
        event?: Event
    }
    addAnnotationModal: {
        opened: boolean
        event?: Event
    }
    addPermissionModal: boolean
    updatePermissionModal: {
        opened: boolean
        permission?: Permission
    }
    addFileToPermissionModal: {
        opened: boolean
        permission?: Permission
    }
    addUserToPermissionModal: {
        opened: boolean
        permission?: Permission
    }
    addEventToPermissionModal: {
        opened: boolean
        permission?: Permission
    }
    addReferenceModal: boolean
    addTipModal: boolean
    addInfoModal: boolean
    addUserModal: boolean
}