import React, {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {dispatchAction} from "../../common";
import {Accordion, Button, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {askBefore, getFileSymbol} from "../common/helpers";
import {CreatePermissionInput, Permission} from "../../../api/permissions";
import {
    addPermissionRequested,
    allPermissionsRequested,
    deletedPermissionRequested,
    permissionsRequestFailed,
    removeEventRequested,
    removeFileRequested,
    removeUserRequested,
    updatedPermissionRequested
} from "../../../store/permissions/actions";
import AddPermissionModal from "../modals/add-permission-modal";
import {openAddEventToPermissionModalRequested, openAddPermissionModalRequested, openAddResourceToPermissionModalRequested, openAddUserToPermissionModalRequested} from "../../../store/modals/actions";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import AddFileToPermissionModal from "../modals/add-file-to-permission-modal";
import AddUserToPermissionModal from "../modals/add-user-to-permission-modal";
import AddEventToPermissionModal from "../modals/add-event-to-permission-modal";
import DatePicker from "react-datepicker";
import {faCopy} from "@fortawesome/free-solid-svg-icons";


const PermissionsCard: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [permissions, setPermissions] = useState<Permission[]>(store.getState().permissions.data)
    const [searchString, setSearchString] = useState<string>('')
    const [filteredPermissions, setFilteredPermissions] = useState<Permission[]>([])

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            if (!state.permissions.loading) setPermissions(state.permissions.data)
        })
    }, [store])

    useEffect(() => {
        if (!searchString) {
            setFilteredPermissions(permissions)
        } else {
            setFilteredPermissions(permissions.filter(permission => permission.title.toLowerCase().includes(searchString.toLowerCase())))
        }
    }, [searchString, permissions])

    useEffect(() => {
        if (token !== undefined) dispatch(allPermissionsRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(permissionsRequestFailed('Nicht Angemeldet'))

    const updatePermission = (permission: Permission) => dispatchAction(token, token => dispatch(updatedPermissionRequested(permission, token)), dispatchError)
    const deletePermission = (permissionId: number) => {
        askBefore(
            "Willst du die Berechtigung wirklich löschen?",
            () => dispatchAction(token, token => dispatch(deletedPermissionRequested(permissionId, token)), dispatchError)
        )
    }

    const removeResource = (permissionId: number, fileId: number) => {
        askBefore(
            "Willst du die Datei wirklich von der Berechtigung entfernen?",
            () => dispatchAction(token, token => dispatch(removeFileRequested({permissionId, fileId}, token)), dispatchError)
        )
    }
    const removeUser = (permissionId: number, userId: number) => {
        askBefore(
            "Willst du den Nutzer wirklich von der Berechtigung entfernen?",
            () => dispatchAction(token, token => dispatch(removeUserRequested({permissionId, userId}, token)), dispatchError)
        )
    }
    const removeEvent = (permissionId: number, eventId: number) => {
        askBefore(
            "Willst du den Kurs wirklich von der Berechtigung entfernen?",
            () => dispatchAction(token, token => dispatch(removeEventRequested({permissionId, eventId}, token)), dispatchError)
        )
    }

    const duplicatePermission = (permission: Permission) => {
        const duplicatedPermission: CreatePermissionInput = {
            title: `${permission.title} Kopie`,
            valid_from: undefined,
            valid_until: undefined,
            is_active: false,
            file_ids: permission.files?.map(file => file.id) || [],
            user_ids: [],
            event_ids: [],
        }
        dispatchAction(token, token => dispatch(addPermissionRequested(duplicatedPermission, token)), dispatchError)
    }

    return (
        <>
            <AddPermissionModal/>
            <AddFileToPermissionModal/>
            <AddUserToPermissionModal/>
            <AddEventToPermissionModal/>
            <Card>
                <Card.Header as="h5">Berechtigungen</Card.Header>
                <Card.Body>
                    <div className="create-permission-button">
                        <div className={'search'}>
                            <label htmlFor="event-search">Suche: </label>
                            <input type="text" className={"search-input"} id={"event-search"} value={searchString} onChange={event => setSearchString(event.target.value)}/>
                        </div>
                        <Button onClick={() => {
                            dispatch(openAddPermissionModalRequested())
                        }}>
                            Neue Berechtigung
                        </Button>
                    </div>
                    {
                        filteredPermissions.length > 0
                            ? <Accordion className="permission-list-item">
                                {filteredPermissions.map(permission => {
                                    return <Card key={permission.id}>
                                        <Accordion.Toggle as={Card.Header} eventKey={permission.id.toString()}>
                                            <div className="title">
                                                <input type="checkbox" checked={permission.is_active} onChange={() => updatePermission({...permission, is_active: !permission.is_active})}/>
                                                <span contentEditable={true} onBlur={event => updatePermission({...permission, title: event.target.innerText})}>{permission.title}</span>
                                                <span> | </span>
                                                <span>
                                                    {permission.created_at.toLocaleDateString('de')} erstellt, {permission.files?.length || 0} {permission.files?.length === 1 ? 'Datei' : 'Dateien'}
                                                </span>
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon={faCopy} focusable={true} className="edit-icon" onClick={() => duplicatePermission(permission)}/>
                                                <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deletePermission(permission.id)}/>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={permission.id.toString()}>
                                            <Card.Body>
                                                <Container>
                                                    <Row>
                                                        <Col md={4}>
                                                            Gültiger Zeitraum
                                                        </Col>
                                                        <Col md={8}>
                                                            <div className={"timespan"}>
                                                                {permission.valid_from
                                                                    ? <div className={"datepicker-input-box"}>
                                                                        <DatePicker
                                                                            id="datepicker"
                                                                            value={permission.valid_from?.toLocaleDateString('de')}
                                                                            onChange={(date: Date) => updatePermission({...permission, valid_from: date})}
                                                                        />
                                                                        <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon"
                                                                                         onClick={() => updatePermission({...permission, valid_from: undefined})}/>
                                                                    </div>
                                                                    : <span onClick={() => updatePermission({...permission, valid_from: new Date()})}>unbegrenzt</span>}
                                                                <span style={{'margin': '0 20px'}}> bis </span>
                                                                {permission.valid_until
                                                                    ? <div className={"datepicker-input-box"}>
                                                                        <DatePicker
                                                                            id="datepicker"
                                                                            value={permission.valid_until?.toLocaleDateString('de')}
                                                                            onChange={(date: Date) => updatePermission({...permission, valid_until: date})}
                                                                        />
                                                                        <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon"
                                                                                         onClick={() => updatePermission({...permission, valid_until: undefined})}/>
                                                                    </div>
                                                                    : <span onClick={() => updatePermission({...permission, valid_until: new Date()})}>unbegrenzt</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md={4}>
                                                            Freigegebene Dateien
                                                        </Col>
                                                        <Col md={8} className="permission-resources">
                                                            <FontAwesomeIcon icon={faPlus} focusable={true}
                                                                             className="add-icon"
                                                                             onClick={() => dispatch(openAddResourceToPermissionModalRequested(permission))}/>
                                                            <ul>
                                                                {
                                                                    permission.files && permission.files.length > 0
                                                                        ? permission.files.map(file => {
                                                                            return (
                                                                                <li key={file.id}>
                                                                                    {getFileSymbol(file.type)}
                                                                                    <span>{file.name}</span>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrash} focusable={true} className="delete-icon"
                                                                                        onClick={() => removeResource(permission.id, file.id)}/>
                                                                                </li>
                                                                            )
                                                                        })
                                                                        : <span>keine Dateien vorhanden</span>
                                                                }
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md={4}>
                                                            Für diese User freigegeben
                                                        </Col>
                                                        <Col md={8} className="permission-users">
                                                            <FontAwesomeIcon icon={faPlus} focusable={true}
                                                                             className="add-icon"
                                                                             onClick={() => dispatch(openAddUserToPermissionModalRequested(permission))}/>
                                                            <ul>
                                                                {
                                                                    permission.users && permission.users.length > 0
                                                                        ? permission.users.map(user => {
                                                                            return (
                                                                                <li key={user.id}>
                                                                                    <span>{user.forename} {user.surname} ({user.email})</span>
                                                                                    <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon"
                                                                                                     onClick={() => removeUser(permission.id, user.id)}/>
                                                                                </li>
                                                                            )
                                                                        })
                                                                        : <span>-</span>
                                                                }
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col md={4}>
                                                            Für diese Kurse freigegeben
                                                        </Col>
                                                        <Col md={8} className="permission-events">
                                                            <FontAwesomeIcon icon={faPlus} focusable={true} className="add-icon"
                                                                             onClick={() => dispatch(openAddEventToPermissionModalRequested(permission))}/>
                                                            <ul>
                                                                {
                                                                    permission.events && permission.events.length > 0
                                                                        ? permission.events.map(event => {
                                                                            return (
                                                                                <li key={event.id}>
                                                                                    <span>{event.title}</span>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrash} focusable={true} className="delete-icon" onClick={() => removeEvent(permission.id, event.id)}/>
                                                                                </li>
                                                                            )
                                                                        })
                                                                        : <span>-</span>
                                                                }
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                })}
                            </Accordion>
                            : <span>keine Berechtigungen vorhanden</span>
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default PermissionsCard;