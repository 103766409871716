import {ApiBase} from "./base";
import {User} from "./user";
import {Permission} from "./permissions";

export enum EventCategory {
    MBSR = 'MBSR',
    MBCL = 'MBCL',
    MCP = 'MCP',
    VERTIEFUNGSANGEBOT = 'VERTIEFUNGSANGEBOT',
    PME = 'PME',
}

export enum EventBookingStatus {
    SEATS_AVAILABLE = 'SEATS_AVAILABLE',
    LIMITED_CAPACITY = 'LIMITED_CAPACITY',
    BOOKED_UP = 'BOOKED_UP',
}

export type Event = {
    id: number
    title: string
    subtitle: string
    when: string
    where: string
    status: EventBookingStatus
    category: EventCategory
    is_active: boolean
    position: number
    annotations: string[]
    users?: User[]
    permissions?: Permission[]
}

export type CreateEventInput = {
    title: string
    subtitle: string
    when: string
    where: string
    category: EventCategory
    booking_status: EventBookingStatus
    is_active: boolean
    position: number
    event_annotations: string[]
    user_ids: number[]
    permission_ids: number[]
}

export type AddParticipantsToEventInput = {
    eventId: number
    userIds: number[]
}

export type RemoveParticipantFromEventInput = {
    eventId: number
    userId: number
}

export class ApiEvents extends ApiBase {
    public static async getEvents(token: string) {
        return ApiEvents.getRequest('/events/', token)
    }

    public static async getEvent(eventId: number, token: string) {
        return ApiEvents.getRequest(`/events/${eventId}`, token)
    }

    public static async addEvent(eventInput: CreateEventInput, token: string) {
        return ApiEvents.postRequest('/events/', eventInput, token)
    }

    public static async addParticipantsToEvent(input: AddParticipantsToEventInput, token: string) {
        const body = {user_ids: input.userIds}
        return ApiEvents.putRequest(`/events/${input.eventId}/add-participants`, body, token)
    }

    public static async removeParticipantFromEvent(input: RemoveParticipantFromEventInput, token: string) {
        const body = {user_id: input.userId}
        return ApiEvents.putRequest(`/events/${input.eventId}/remove-participant`, body, token)
    }

    public static async updateEvent(event: Event, token: string) {
        return ApiEvents.putRequest(`/events/${event.id}`, event, token)
    }

    public static async deleteEvent(eventId: number, token: string) {
        return ApiEvents.deleteRequest(`/events/${eventId}`, token)
    }
}
