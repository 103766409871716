import React, {Ref, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {dispatchAction} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {ReferenceItem} from "../../../api/references";
import {allReferencesRequested, deletedReferenceRequested, referencesRequestFailed, updatedReferenceRequested} from "../../../store/index-content/actions";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {askBefore} from "../common/helpers";
import {openAddReferenceModalRequested} from "../../../store/modals/actions";
import AddReferenceModal from "../modals/add-reference-modal";

interface IProps {
    token: string | undefined
}

const ReferencesCard: React.FC<IProps> = ({token}) => {
    const store = useStore()
    const dispatch = useDispatch()

    const [dragId, setDragId] = useState<string | undefined>();
    const [references, setReferences] = useState<ReferenceItem[]>(store.getState().references.data)

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState()
            if (!state.references.loading) setReferences(state.references.data)
        })
        return () => unsubscribe()
    }, [store])

    useEffect(() => {
        if (token !== undefined) dispatch(allReferencesRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(referencesRequestFailed('Nicht Angemeldet'))

    const updateReference = (reference: ReferenceItem) => dispatchAction(token, token => dispatch(updatedReferenceRequested(reference, token)), dispatchError)
    const deleteReference = (referenceId: number) => {
        askBefore(
            'Willst die die Referenz wirklich löschen?',
            () => dispatchAction(token, token => dispatch(deletedReferenceRequested(referenceId, token)), dispatchError)
        )
    }

    const handleDrag = (event: any) => setDragId(event.currentTarget.id)

    const handleDrop = (event: any) => {
        const dragReference = references.find(reference => reference.id.toString() === dragId)
        const dropReference = references.find(reference => reference.id.toString() === event.currentTarget.id)

        if (dragReference === undefined || dropReference === undefined) return

        references.forEach(reference => {
            if (reference.id.toString() === dragId) {
                updateReference({...reference, position: dropReference.position})
            }
            if (reference.id.toString() === event.currentTarget.id) {
                updateReference({...reference, position: dragReference.position})
            }
        })
    }

    return <Card>
        <Card.Header as="h5">Referenzen</Card.Header>
        <Card.Body>
            <div className="content-list">
                <div className="add-icon-wrapper">
                    <FontAwesomeIcon icon={faPlus} focusable={true} className="add-icon" onClick={() => dispatch(openAddReferenceModalRequested())}/>
                    <AddReferenceModal/>
                </div>
                {references.length > 0
                    ? <table>
                        <tbody>
                        {references.map(reference => {
                            return (
                                <tr key={reference.id} id={reference.id.toString()} className="hover-item" draggable={true} onDragOver={event => event.preventDefault()}
                                    onDragStart={handleDrag} onDrop={handleDrop}>
                                    <td width="100%" className="two-inputs">
                                        <span contentEditable={true} onBlur={event => updateReference({...reference, text: event.target.innerText})}>
                                            {reference.text}
                                        </span>
                                        <span> - </span>
                                        <span contentEditable={true} onBlur={event => updateReference({...reference, author: event.target.innerText})}>
                                            {reference.author}
                                        </span>
                                    </td>
                                    <td width="5%" className="icon-column">
                                        <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deleteReference(reference.id)}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : <span className="no-data">keine Referenzen vorhanden</span>}
            </div>
        </Card.Body>
    </Card>
}

export default ReferencesCard