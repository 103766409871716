import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {Event} from "../../../api/events";
import {useDispatch, useStore} from "react-redux";
import {closeAddAnnotationModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {eventsRequestFailed, updatedEventRequested} from "../../../store/events/action";
import {useSetTokenEffect} from "../../../pages/content/hooks";

const AddAnnotationToEventModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [annotation, setAnnotation] = useState<string>("")
    const [event, setEvent] = useState<Event>(store.getState().modals.addAnnotationModal.event)
    const [showModal, setShowModal] = useState(store.getState().modals.addAnnotationModal.opened)

    const closeModal = () => {
        setAnnotation("")
        dispatch(closeAddAnnotationModalRequested())
    }

    const dispatchError = () => dispatch(eventsRequestFailed('Nicht Angemeldet'))
    const addAnnotation = () => dispatchAction(token, token => dispatch(updatedEventRequested({...event, annotations: [...event.annotations, annotation]}, token)), dispatchError)

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            setEvent(state.modals.addAnnotationModal.event)
            setShowModal(state.modals.addAnnotationModal.opened)
        })
    }, [dispatch, store, token])

    return event
        ? <Modal show={showModal} onHide={closeModal}
                 centered={true} className="create-event-modal">
            <Modal.Header closeButton>
                <Modal.Title>Anmerkung hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Container>
                        <Row>
                            <Col md={2}>Anmerkung</Col>
                            <Col md={10}>
                                <input value={annotation} onChange={event => setAnnotation(event.target.value)}/>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    if (annotation === "") {
                        alert("Keine Anmerkung vorhanden")
                    }
                    addAnnotation()
                    closeModal()
                }}>
                    Hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
        : <></>
}

export default AddAnnotationToEventModal