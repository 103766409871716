import {action} from 'typesafe-actions'
import {AdminActionTypes} from "./types";
import {TokenResponse} from "../../api/auth";

export const loginRequested = (email: string, password: string) => action(AdminActionTypes.LOGIN_REQUEST, {
    username: email,
    password,
    scope: 'admin'
})
export const loginSucceeded = (tokenResponse: TokenResponse) => action(AdminActionTypes.LOGIN_SUCCESS, tokenResponse)
export const loginFailed = (message: string) => action(AdminActionTypes.LOGIN_ERROR, message)
export const logout = () => action(AdminActionTypes.LOGOUT)