import React from "react";

export const dispatchAction = (token: string | undefined, dispatchCallback: (token: string) => any, dispatchErrorCallback: () => any) => {
    return token ? dispatchCallback(token) : dispatchErrorCallback()
}

export const handleKeyPress = (event: React.KeyboardEvent, enterCallback: () => any) => {
    switch (event.key) {
        case 'Enter':
            return enterCallback()
    }
}