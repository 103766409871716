import {Reducer} from "redux";
import {UsersActionTypes, UsersState} from "./types";

const initialState: UsersState = {
    data: [],
    errors: undefined,
    loading: false,
}

export const usersReducer: Reducer<UsersState> = (state = initialState, action) => {
    switch (action.type) {
        case UsersActionTypes.ADD_USER_REQUESTED:
        case UsersActionTypes.ALL_USERS_REQUESTED:
        case UsersActionTypes.UPDATED_USER_REQUESTED:
        case UsersActionTypes.DELETED_USER_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case UsersActionTypes.ADD_USER_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case UsersActionTypes.ALL_USERS_SUCCEEDED:
            return {...state, loading: false, data: action.payload, errors: undefined}

        case UsersActionTypes.UPDATED_USER_SUCCEEDED:
            return {...state, loading: false, data: state.data.map(user => user.id === action.payload.id ? action.payload : user)}

        case UsersActionTypes.DELETED_USER_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(user => user.id !== action.payload)}

        case UsersActionTypes.USERS_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}