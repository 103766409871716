import {BaseState} from "../common";
import {InfoItem} from "../../api/infos";
import {TipItem} from "../../api/tips";
import {ReferenceItem} from "../../api/references";

export enum InfosActionTypes {
    ADD_INFO_REQUESTED = '@@infos/ADD_INFO_REQUESTED',
    ADD_INFO_SUCCEEDED = '@@infos/ADD_INFO_SUCCEEDED',
    ALL_INFOS_REQUESTED = '@@infos/ALL_INFOS_REQUESTED',
    ALL_INFOS_SUCCEEDED = '@@infos/ALL_INFOS_SUCCEEDED',
    UPDATED_INFO_REQUESTED = '@@infos/UPDATE_INFO_REQUESTED',
    UPDATED_INFO_SUCCEEDED = '@@infos/UPDATE_INFO_SUCCEEDED',
    DELETED_INFO_REQUESTED = '@@infos/DELETED_INFO_REQUESTED',
    DELETED_INFO_SUCCEEDED = '@@infos/DELETED_INFO_SUCCEEDED',
    INFOS_REQUEST_FAILED = '@@infos/INFOS_REQUEST_FAILED',
}

export enum TipsActionTypes {
    ADD_TIP_REQUESTED = '@@tips/ADD_TIP_REQUESTED',
    ADD_TIP_SUCCEEDED = '@@tips/ADD_TIP_SUCCEEDED',
    ALL_TIPS_REQUESTED = '@@tips/ALL_TIPS_REQUESTED',
    ALL_TIPS_SUCCEEDED = '@@tips/ALL_TIPS_SUCCEEDED',
    UPDATED_TIP_REQUESTED = '@@tips/UPDATE_TIP_REQUESTED',
    UPDATED_TIP_SUCCEEDED = '@@tips/UPDATE_TIP_SUCCEEDED',
    DELETED_TIP_REQUESTED = '@@tips/DELETED_TIP_REQUESTED',
    DELETED_TIP_SUCCEEDED = '@@tips/DELETED_TIP_SUCCEEDED',
    TIPS_REQUEST_FAILED = '@@tips/TIPS_REQUEST_FAILED',
}

export enum ReferencesActionTypes {
    ADD_REFERENCE_REQUESTED = '@@references/ADD_REFERENCE_REQUESTED',
    ADD_REFERENCE_SUCCEEDED = '@@references/ADD_REFERENCE_SUCCEEDED',
    ALL_REFERENCES_REQUESTED = '@@references/ALL_REFERENCES_REQUESTED',
    ALL_REFERENCES_SUCCEEDED = '@@references/ALL_REFERENCES_SUCCEEDED',
    UPDATED_REFERENCE_REQUESTED = '@@references/UPDATE_REFERENCE_REQUESTED',
    UPDATED_REFERENCE_SUCCEEDED = '@@references/UPDATE_REFERENCE_SUCCEEDED',
    DELETED_REFERENCE_REQUESTED = '@@references/DELETED_REFERENCE_REQUESTED',
    DELETED_REFERENCE_SUCCEEDED = '@@references/DELETED_REFERENCE_SUCCEEDED',
    REFERENCES_REQUEST_FAILED = '@@references/REFERENCES_REQUEST_FAILED',
}


export interface InfosState extends BaseState {
    readonly data: InfoItem[]
}

export interface TipsState extends BaseState {
    readonly data: TipItem[]
}

export interface ReferencesState extends BaseState {
    readonly data: ReferenceItem[]
}
