import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {closeAddUserToPermissionModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import SelectSearch from 'react-select-search';
import {Permission} from "../../../api/permissions";
import {addUserRequested, permissionsRequestFailed} from "../../../store/permissions/actions";
import {User} from "../../../api/user";
import {allUsersRequested} from "../../../store/users/action";

const AddUserToPermissionModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>()
    const [permission, setPermission] = useState<Permission>(store.getState().modals.addUserToPermissionModal.permission)
    const [showModal, setShowModal] = useState(store.getState().modals.addUserToPermissionModal.opened)

    const [users, setUsers] = useState<User[]>(store.getState().users.data)

    const closeModal = () => {
        setSelectedUserId(undefined)
        dispatch(closeAddUserToPermissionModalRequested())
    }

    const dispatchError = () => dispatch(permissionsRequestFailed('Nicht Angemeldet'))
    const addUser = () => {
        if (selectedUserId) {
            dispatchAction(token, token => dispatch(addUserRequested({permissionId: permission.id, userId: selectedUserId}, token)), dispatchError)
        }
    }

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        if (token !== undefined) dispatch(allUsersRequested(token))
        return store.subscribe(() => {
            const state = store.getState()
            setPermission(state.modals.addUserToPermissionModal.permission)
            setShowModal(state.modals.addUserToPermissionModal.opened)
            if (!state.users.loading) setUsers(state.users.data)
        })
    }, [dispatch, store, token])

    return permission
        ? <Modal show={showModal} onHide={closeModal}
                 centered={true} className="add-User-to-permission-modal">
            <Modal.Header closeButton>
                <Modal.Title>User hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectSearch
                    options={users.map(user => ({name: `${user.forename} ${user.surname} (${user.email})`, value: user.id}))}
                    value={selectedUserId?.toString()}
                    onChange={user => setSelectedUserId(parseInt(user.toString()))}
                    placeholder="User auswählen"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    addUser()
                    closeModal()
                }}>
                    Hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
        : <></>
}

export default AddUserToPermissionModal