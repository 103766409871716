import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useStore} from "react-redux";
import Loading from "./loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {logout} from "../../store/admin/actions";
import './header.css';

function Header() {
    const [loading, setLoading] = useState(false)
    const store = useStore()
    const dispatch = useDispatch()

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState()
            for (const item in state) {
                if (state.hasOwnProperty(item) && state[item].loading) {
                    setLoading(true)
                    return
                }
            }
            setLoading(false)
        })
        return () => unsubscribe()
    }, [store])

    const signOut = () => {
        localStorage.removeItem('accessToken')
        dispatch(logout())
    }

    return (
        <header className={'background-colorful'}>
            <div className="sign-out"><FontAwesomeIcon icon={faSignOutAlt} focusable={true} className="sign-out-icon" onClick={signOut}/></div>
            <Link to={'/'}>
                <span className={'text-light'}>Admin Konsole</span>
            </Link>
            {loading ? <div className="header-loading"><Loading/></div> : <></>}
        </header>
    );
}

export default Header;