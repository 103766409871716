import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {dispatchAction} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {allTipsRequested, deletedTipRequested, tipsRequestFailed, updatedTipRequested} from "../../../store/index-content/actions";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {askBefore} from "../common/helpers";
import {TipItem} from "../../../api/tips";
import AddTipModal from "../modals/add-tip-modal";
import {openAddTipModalRequested} from "../../../store/modals/actions";

interface IProps {
    token: string | undefined
}

const TipsCard: React.FC<IProps> = ({token}) => {
    const store = useStore()
    const dispatch = useDispatch()

    const [tips, setTips] = useState<TipItem[]>(store.getState().tips.data)

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState()
            if (!state.tips.loading) setTips(state.tips.data)
        })
        return () => unsubscribe()
    }, [store])

    useEffect(() => {
        if (token !== undefined) dispatch(allTipsRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(tipsRequestFailed('Nicht Angemeldet'))

    const updateTip = (tip: TipItem) => dispatchAction(token, token => dispatch(updatedTipRequested(tip, token)), dispatchError)
    const deleteTip = (tipId: number) => {
        askBefore(
            'Willst den Tipp wirklich löschen?',
            () => dispatchAction(token, token => dispatch(deletedTipRequested(tipId, token)), dispatchError)
        )
    }

    return <Card>
        <Card.Header as="h5">Tipps</Card.Header>
        <Card.Body>
            <div className="content-list">
                <div className="add-icon-wrapper">
                    <FontAwesomeIcon icon={faPlus} focusable={true} className="add-icon" onClick={() => dispatch(openAddTipModalRequested())}/>
                    <AddTipModal/>
                </div>
                {tips.length > 0
                    ? <table>
                        <tbody>
                        {tips.map(tip => {
                            return (
                                <tr key={tip.id} className="hover-item">
                                    <td width="100%" className="two-inputs">
                                        <span contentEditable={true} onBlur={event => updateTip({...tip, text: event.target.innerText})}>
                                            {tip.text}
                                        </span>
                                    </td>
                                    <td width="5%" className="icon-column">
                                        <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deleteTip(tip.id)}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : <span className="no-data">keine Tipps vorhanden</span>}
            </div>
        </Card.Body>
    </Card>
}

export default TipsCard