import React, {useState} from 'react';
import {useStore} from "react-redux";
import FilesCard from "../../components/content/cards/files-card";
import {useSetTokenEffect} from "./hooks";

function FilesPage() {
    const store = useStore()
    const [token, setToken] = useState<string>()

    useSetTokenEffect(store, setToken)

    return <FilesCard token={token}/>
}

export default FilesPage;