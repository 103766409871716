import {call, takeEvery} from "redux-saga/effects";
import {getDeleteRequestHandler, getRequestHandler} from "../common";
import {EventsActionTypes} from "./types";
import {
    addEventRequested,
    addEventSucceeded,
    addParticipantsRequested,
    addParticipantsSucceeded,
    allEventsRequested,
    allEventsSucceeded,
    deletedEventRequested,
    deletedEventSucceeded,
    eventsRequestFailed,
    removeParticipantRequested,
    removeParticipantSucceeded, updatedEventRequested,
    updatedEventSucceeded
} from "./action";
import {ApiEvents} from "../../api/events";

export default function* watchEventRequests() {
    yield takeEvery(EventsActionTypes.ADD_EVENT_REQUESTED, getRequestHandler<typeof addEventRequested>(
        addEventSucceeded, eventsRequestFailed,
        (action) => call(ApiEvents.addEvent, action.payload.eventData, action.payload.token)
    ))
    yield takeEvery(EventsActionTypes.ALL_EVENTS_REQUESTED, getRequestHandler<typeof allEventsRequested>(
        allEventsSucceeded, eventsRequestFailed,
        (action) => call(ApiEvents.getEvents, action.payload)
    ))
    yield takeEvery(EventsActionTypes.ADD_PARTICIPANTS_REQUESTED, getRequestHandler<typeof addParticipantsRequested>(
        addParticipantsSucceeded, eventsRequestFailed,
        (action) => call(ApiEvents.addParticipantsToEvent, action.payload.participants, action.payload.token)
    ))
    yield takeEvery(EventsActionTypes.REMOVE_PARTICIPANT_REQUESTED, getRequestHandler<typeof removeParticipantRequested>(
        removeParticipantSucceeded, eventsRequestFailed,
        (action) => call(ApiEvents.removeParticipantFromEvent, action.payload.participant, action.payload.token)
    ))
    yield takeEvery(EventsActionTypes.UPDATED_EVENT_REQUESTED, getRequestHandler<typeof updatedEventRequested>(
        updatedEventSucceeded, eventsRequestFailed,
        (action) => call(ApiEvents.updateEvent, action.payload.event, action.payload.token)
    ))
    yield takeEvery(EventsActionTypes.DELETED_EVENT_REQUESTED, getDeleteRequestHandler<typeof deletedEventRequested>(
        (action) => deletedEventSucceeded(action.payload.eventId),
        eventsRequestFailed,
        (action) => call(ApiEvents.deleteEvent, action.payload.eventId, action.payload.token)
    ))
}