import {BaseState} from "../common"
import {Event} from "../../api/events"

export enum EventsActionTypes {
    ADD_EVENT_REQUESTED = '@@events/ADD_EVENT_REQUESTED',
    ADD_EVENT_SUCCEEDED = '@@events/ADD_EVENT_SUCCEEDED',
    ALL_EVENTS_REQUESTED = '@@events/ALL_EVENTS_REQUESTED',
    ALL_EVENTS_SUCCEEDED = '@@events/ALL_EVENTS_SUCCEEDED',
    ADD_PARTICIPANTS_REQUESTED = '@@events/ADD_PARTICIPANTS_REQUESTED',
    ADD_PARTICIPANTS_SUCCEEDED = '@@events/ADD_PARTICIPANTS_SUCCEEDED',
    REMOVE_PARTICIPANT_REQUESTED = '@@events/REMOVE_PARTICIPANTS_REQUESTED',
    REMOVE_PARTICIPANT_SUCCEEDED = '@@events/REMOVE_PARTICIPANTS_SUCCEEDED',
    UPDATED_EVENT_REQUESTED = '@@events/UPDATE_EVENT_REQUESTED',
    UPDATED_EVENT_SUCCEEDED = '@@events/UPDATE_EVENT_SUCCEEDED',
    DELETED_EVENT_REQUESTED = '@@events/DELETED_EVENT_REQUESTED',
    DELETED_EVENT_SUCCEEDED = '@@events/DELETED_EVENT_SUCCEEDED',
    EVENTS_REQUEST_FAILED = '@@events/EVENTS_REQUEST_FAILED',
}


export interface EventsState extends BaseState {
    readonly data: Event[]
}