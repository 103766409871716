import {ApiBase} from "./base";

const querystring = require('querystring');


export type TokenRequestInput = {
    username: string
    password: string
    scope: string
}

export type TokenResponse = {
    access_token: string
    token_type: string
}

export class ApiAuth extends ApiBase {
    public static async requestToken(loginData: TokenRequestInput): Promise<TokenResponse> {
        const configs: RequestInit = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
            },
            body: querystring.stringify({...loginData, grant_type: 'password'}),
        }
        const response = await fetch(`${ApiAuth.base_url}/authentication/token`, configs)
        return response.json()
    }
}
