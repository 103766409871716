import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {Event} from "../../../api/events";
import {useDispatch, useStore} from "react-redux";
import {closeAddParticipantModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {addParticipantsRequested, eventsRequestFailed} from "../../../store/events/action";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {User} from "../../../api/user";
import {MultiSelect} from 'react-multi-select-component';
import {allUsersRequested} from "../../../store/users/action";

const AddUserToEventModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [users, setUsers] = useState<User[]>([])
    const [selectedUsers, setSelectedUsers] = useState<[]>([])
    const [event, setEvent] = useState<Event>(store.getState().modals.addParticipantModal.event)
    const [showUpdateModal, setShowUpdateModal] = useState(store.getState().modals.addParticipantModal.opened)

    const closeModal = () => {
        setSelectedUsers([])
        dispatch(closeAddParticipantModalRequested())
    }

    const dispatchError = () => dispatch(eventsRequestFailed('Nicht Angemeldet'))
    const addUsers = () => {
        dispatchAction(token, token => dispatch(
                addParticipantsRequested({eventId: event.id, userIds: selectedUsers.map((user: any) => user.value)}, token)),
            dispatchError
        )
    }

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        if (token !== undefined) dispatch(allUsersRequested(token))
        return store.subscribe(() => {
            const state = store.getState()
            setEvent(state.modals.addParticipantModal.event)
            setShowUpdateModal(state.modals.addParticipantModal.opened)
            if (!state.users.loading) setUsers(state.users.data)
        })
    }, [dispatch, store, token])

    return event
        ? <Modal show={showUpdateModal} onHide={closeModal}
                 centered={true} className="create-event-modal">
            <Modal.Header closeButton>
                <Modal.Title>Teilnehmer hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MultiSelect
                    options={users.map(user => ({label: `${user.forename} ${user.surname} (${user.email})`, value: user.id}))}
                    value={selectedUsers}
                    onChange={setSelectedUsers}
                    labelledBy="Wähle Nutzer zum Hinzufügen aus"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    addUsers()
                    closeModal()
                }}>
                    Hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
        : <></>
}

export default AddUserToEventModal