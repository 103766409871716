import {call, takeEvery} from "redux-saga/effects";
import {getDeleteRequestHandler, getRequestHandler} from "../common";
import {UsersActionTypes} from "./types";
import {
    addUserRequested,
    addUserSucceeded,
    allUsersRequested,
    allUsersSucceeded,
    deletedUserRequested,
    deletedUserSucceeded,
    updatedUserRequested,
    updatedUserSucceeded,
    usersRequestFailed
} from "./action";
import {ApiUsers} from "../../api/user";

export default function* watchUserRequests() {
    yield takeEvery(UsersActionTypes.ADD_USER_REQUESTED, getRequestHandler<typeof addUserRequested>(
        addUserSucceeded, usersRequestFailed,
        (action) => call(ApiUsers.createUser, action.payload.userData, action.payload.token)
    ))
    yield takeEvery(UsersActionTypes.ALL_USERS_REQUESTED, getRequestHandler<typeof allUsersRequested>(
        allUsersSucceeded, usersRequestFailed,
        (action) => call(ApiUsers.getUsers, action.payload)
    ))
    yield takeEvery(UsersActionTypes.UPDATED_USER_REQUESTED, getRequestHandler<typeof updatedUserRequested>(
        updatedUserSucceeded, usersRequestFailed,
        (action) => call(ApiUsers.updateUser, action.payload.user, action.payload.token)
    ))
    yield takeEvery(UsersActionTypes.DELETED_USER_REQUESTED, getDeleteRequestHandler<typeof deletedUserRequested>(
        (action) => deletedUserSucceeded(action.payload.userId),
        usersRequestFailed,
        (action) => call(ApiUsers.deleteUser, action.payload.userId, action.payload.token)
    ))
}