import {put} from "redux-saga/effects";

export type BaseState = {
    readonly loading: boolean
    readonly errors?: string
}

export const getRequestHandler = <ActionType extends ((...args: any) => any)>(successActionBuilder: any, failActionBuilder: (message: string) => any, requestCallback: (action: ReturnType<ActionType>) => any) => {
    return function* (action: ReturnType<any>): Generator {
        try {
            const response: any = yield requestCallback(action)
            if (response.error) {
                yield put(failActionBuilder(response.error))
            } else {
                yield put(successActionBuilder(response))
            }
        } catch (error) {
            if (error instanceof Error && error.stack) {
                yield put(failActionBuilder(error.stack))
            } else {
                yield put(failActionBuilder('An unknown error occured.'))
            }
        }
    }
}
export const getDeleteRequestHandler = <ActionType extends ((...args: any) => any)>(successActionBuilderCallback: (action: ReturnType<ActionType>) => any, failActionBuilder: (message: string) => any, requestCallback: (action: ReturnType<ActionType>) => any) => {
    return function* (action: ReturnType<any>): Generator {
        try {
            yield requestCallback(action)
            yield put(successActionBuilderCallback(action))
        } catch (error) {
            if (error instanceof Error && error.stack) {
                yield put(failActionBuilder(error.stack))
            } else {
                yield put(failActionBuilder('An unknown error occured.'))
            }
        }
    }
}