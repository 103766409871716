import {ApiBase} from "./base";

export type ReferenceItem = {
    id: number
    text: string
    author: string
    position: number
}

export type CreateReferenceInput = {
    cite: string
    author: string
    position: number
}

export class ApiReferences extends ApiBase {
    public static async getReferences(token: string) {
        return ApiReferences.getRequest('/references/', token)
    }

    public static async getReference(referenceId: number, token: string) {
        return ApiReferences.getRequest(`/references/${referenceId}`, token)
    }

    public static async addReference(referenceInput: CreateReferenceInput, token: string) {
        return ApiReferences.postRequest('/references/', referenceInput, token)
    }

    public static async updateReference(reference: ReferenceItem, token: string) {
        return ApiReferences.putRequest(`/references/${reference.id}`, reference, token)
    }

    public static async deleteReference(referenceId: number, token: string) {
        return ApiReferences.deleteRequest(`/references/${referenceId}`, token)
    }
}
