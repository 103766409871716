import {action} from "typesafe-actions";
import {InfosActionTypes, ReferencesActionTypes, TipsActionTypes} from "./types";
import {CreateInfoInput, InfoItem} from "../../api/infos";
import {TipItem} from "../../api/tips";
import {CreateReferenceInput, ReferenceItem} from "../../api/references";

export const addInfoRequested = (infoInput: CreateInfoInput, token: string) => action(InfosActionTypes.ADD_INFO_REQUESTED, {infoInput, token})
export const addInfoSucceeded = (info: InfoItem) => action(InfosActionTypes.ADD_INFO_SUCCEEDED, info)
export const allInfosRequested = (token: string) => action(InfosActionTypes.ALL_INFOS_REQUESTED, token)
export const allInfosSucceeded = (infos: InfoItem[]) => action(InfosActionTypes.ALL_INFOS_SUCCEEDED, infos)
export const updatedInfoRequested = (info: InfoItem, token: string) => action(InfosActionTypes.UPDATED_INFO_REQUESTED, {info, token})
export const updatedInfoSucceeded = (info: InfoItem) => action(InfosActionTypes.UPDATED_INFO_SUCCEEDED, info)
export const deletedInfoRequested = (infoId: number, token: string) => action(InfosActionTypes.DELETED_INFO_REQUESTED, {infoId, token})
export const deletedInfoSucceeded = (infoId: number) => action(InfosActionTypes.DELETED_INFO_SUCCEEDED, infoId)
export const infosRequestFailed = (message: string) => action(InfosActionTypes.INFOS_REQUEST_FAILED, message)

export const addTipRequested = (text: string, token: string) => action(TipsActionTypes.ADD_TIP_REQUESTED, {text, token})
export const addTipSucceeded = (tip: TipItem) => action(TipsActionTypes.ADD_TIP_SUCCEEDED, tip)
export const allTipsRequested = (token: string) => action(TipsActionTypes.ALL_TIPS_REQUESTED, token)
export const allTipsSucceeded = (tips: TipItem[]) => action(TipsActionTypes.ALL_TIPS_SUCCEEDED, tips)
export const updatedTipRequested = (tip: TipItem, token: string) => action(TipsActionTypes.UPDATED_TIP_REQUESTED, {tip, token})
export const updatedTipSucceeded = (tip: TipItem) => action(TipsActionTypes.UPDATED_TIP_SUCCEEDED, tip)
export const deletedTipRequested = (tipId: number, token: string) => action(TipsActionTypes.DELETED_TIP_REQUESTED, {tipId, token})
export const deletedTipSucceeded = (tipId: number) => action(TipsActionTypes.DELETED_TIP_SUCCEEDED, tipId)
export const tipsRequestFailed = (message: string) => action(TipsActionTypes.TIPS_REQUEST_FAILED, message)

export const addReferenceRequested = (referenceInput: CreateReferenceInput, token: string) => action(ReferencesActionTypes.ADD_REFERENCE_REQUESTED, {referenceInput, token})
export const addReferenceSucceeded = (reference: ReferenceItem) => action(ReferencesActionTypes.ADD_REFERENCE_SUCCEEDED, reference)
export const allReferencesRequested = (token: string) => action(ReferencesActionTypes.ALL_REFERENCES_REQUESTED, token)
export const allReferencesSucceeded = (references: ReferenceItem[]) => action(ReferencesActionTypes.ALL_REFERENCES_SUCCEEDED, references)
export const updatedReferenceRequested = (reference: ReferenceItem, token: string) => action(ReferencesActionTypes.UPDATED_REFERENCE_REQUESTED, {reference, token})
export const updatedReferenceSucceeded = (reference: ReferenceItem) => action(ReferencesActionTypes.UPDATED_REFERENCE_SUCCEEDED, reference)
export const deletedReferenceRequested = (referenceId: number, token: string) => action(ReferencesActionTypes.DELETED_REFERENCE_REQUESTED, {referenceId, token})
export const deletedReferenceSucceeded = (referenceId: number) => action(ReferencesActionTypes.DELETED_REFERENCE_SUCCEEDED, referenceId)
export const referencesRequestFailed = (message: string) => action(ReferencesActionTypes.REFERENCES_REQUEST_FAILED, message)
