import React, {useState} from 'react';
import {useStore} from "react-redux";
import UsersCard from "../../components/content/cards/user-card";
import {useSetTokenEffect} from "./hooks";

function UsersPage() {
    const store = useStore()
    const [token, setToken] = useState<string>()

    useSetTokenEffect(store, setToken)

    return <UsersCard token={token}/>
}

export default UsersPage;