import {applyMiddleware, combineReducers, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {all, fork} from "redux-saga/effects"
import {adminReducer} from "./admin/reducer"
import adminSaga from "./admin/saga"
import indexContentSaga from "./index-content/saga"
import eventsSaga from "./events/saga"
import permissionsSaga from "./permissions/saga"
import resourcesSaga from "./files/saga"
import usersSaga from "./users/saga"
import {infosReducer, referencesReducer, tipsReducer} from "./index-content/reducer"
import {usersReducer} from "./users/reducer"
import {filesReducer} from "./files/reducer"
import {permissionsReducer} from "./permissions/reducer"
import {eventsReducer} from "./events/reducer"
import {modalsReducer} from "./modals/reducer";


export const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware()

    function* rootSaga() {
        yield all([fork(adminSaga), fork(indexContentSaga), fork(eventsSaga), fork(permissionsSaga), fork(resourcesSaga), fork(usersSaga)])
    }

    const store = createStore(
        combineReducers({
            admin: adminReducer,
            events: eventsReducer,
            infos: infosReducer,
            tips: tipsReducer,
            references: referencesReducer,
            permissions: permissionsReducer,
            files: filesReducer,
            users: usersReducer,
            modals: modalsReducer,
        }),
        applyMiddleware(sagaMiddleware)
    )

    sagaMiddleware.run(rootSaga)
    return store
}



