import {ApiBase} from "./base";
import {File} from "./files";
import {User} from "./user";
import {Event} from "./events";

export type Permission = {
    id: number
    title: string
    created_at: Date
    valid_from?: Date
    valid_until?: Date
    is_active: boolean
    files?: File[]
    users?: User[]
    events?: Event[]
}

type ResponsePermission = {
    id: number
    title: string
    created_at: string
    valid_from?: string
    valid_until?: string
    is_active: boolean
    files?: File[]
    users?: User[]
    events?: Event[]
}

export type CreatePermissionInput = {
    title: string
    valid_from?: Date
    valid_until?: Date
    is_active: boolean
    file_ids: number[]
    user_ids: number[]
    event_ids: number[]
}

export type AddOrRemoveUserToPermissionInput = {
    permissionId: number
    userId: number
}

export type AddOrRemoveEventToPermissionInput = {
    permissionId: number
    eventId: number
}

export type AddOrRemoveFileToPermissionInput = {
    permissionId: number
    fileId: number
}

export class ApiPermissions extends ApiBase {

    public static async getPermissions(token: string) {
        const permissions: ResponsePermission[] = await ApiPermissions.getRequest('/permissions/', token)
        return permissions.map(ApiPermissions.parsePermission)
    }

    public static async getPermission(permissionId: number, token: string) {
        const permission: ResponsePermission = await ApiPermissions.getRequest(`/permissions/${permissionId}`, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async addPermission(permissionInput: CreatePermissionInput, token: string) {
        function toISOLocal(d: any) {
            var z = (n: any) => ('0' + n).slice(-2);
            var zz = (n: any) => ('00' + n).slice(-3);
            var off = d.getTimezoneOffset();
            var sign = off > 0 ? '-' : '+';
            off = Math.abs(off);

            return d.getFullYear() + '-'
                + z(d.getMonth() + 1) + '-' +
                z(d.getDate()) + 'T' +
                z(d.getHours()) + ':' +
                z(d.getMinutes()) + ':' +
                z(d.getSeconds()) + '.' +
                zz(d.getMilliseconds()) +
                sign + z(off / 60 | 0) + ':' + z(off % 60);
        }

        const body = {
            ...permissionInput,
            valid_from: permissionInput.valid_from ? toISOLocal(permissionInput.valid_from) : undefined,
            valid_until: permissionInput.valid_until ? toISOLocal(permissionInput.valid_until) : undefined,
        }
        const permission: ResponsePermission = await ApiPermissions.postRequest('/permissions/', body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async addUserToPermission(input: AddOrRemoveUserToPermissionInput, token: string) {
        const body = {user_id: input.userId}
        const permission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${input.permissionId}/add-user`, body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async removeUserFromPermission(input: AddOrRemoveUserToPermissionInput, token: string) {
        const body = {user_id: input.userId}
        const permission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${input.permissionId}/remove-user`, body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async addEventToPermission(input: AddOrRemoveEventToPermissionInput, token: string) {
        const body = {event_id: input.eventId}
        const permission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${input.permissionId}/add-event`, body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async removeEventFromPermission(input: AddOrRemoveEventToPermissionInput, token: string) {
        const body = {event_id: input.eventId}
        const permission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${input.permissionId}/remove-event`, body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async addResourceToPermission(input: AddOrRemoveFileToPermissionInput, token: string) {
        const body = {file_id: input.fileId}
        const permission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${input.permissionId}/add-file`, body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async removeResourceFromPermission(input: AddOrRemoveFileToPermissionInput, token: string) {
        const body = {file_id: input.fileId}
        const permission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${input.permissionId}/remove-file`, body, token)
        return ApiPermissions.parsePermission(permission)
    }

    public static async updatePermission(permission: Permission, token: string) {
        const updatedPermission: ResponsePermission = await ApiPermissions.putRequest(`/permissions/${permission.id}`, permission, token)
        return ApiPermissions.parsePermission(updatedPermission)
    }

    public static async deletePermission(permissionId: number, token: string) {
        return ApiPermissions.deleteRequest(`/permissions/${permissionId}`, token)
    }

    private static parsePermission(permission: ResponsePermission): Permission {
        return {
            ...permission,
            created_at: new Date(permission.created_at),
            valid_from: permission.valid_from ? new Date(permission.valid_from) : undefined,
            valid_until: permission.valid_until ? new Date(permission.valid_until) : undefined,
        }
    }
}
