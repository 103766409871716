import {call, takeEvery} from 'redux-saga/effects'
import {loginFailed, loginRequested, loginSucceeded} from "./actions";
import {ApiAuth} from "../../api/auth";
import {AdminActionTypes} from "./types";
import {getRequestHandler} from "../common";

export default function* watchLoginRequest() {
    yield takeEvery(AdminActionTypes.LOGIN_REQUEST, getRequestHandler<typeof loginRequested>(
        loginSucceeded, loginFailed,
        (action) => call(ApiAuth.requestToken, action.payload)
    ))
}
