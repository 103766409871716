import {Reducer} from "redux";
import {ModalActionTypes, ModalsState} from "./types";

const initialModalsState: ModalsState = {
    createEventModal: false,
    updateEventModal: {opened: false},
    addParticipantModal: {opened: false},
    addAnnotationModal: {opened: false},
    addPermissionModal: false,
    updatePermissionModal: {opened: false},
    addFileToPermissionModal: {opened: false},
    addUserToPermissionModal: {opened: false},
    addEventToPermissionModal: {opened: false},
    addReferenceModal: false,
    addTipModal: false,
    addInfoModal: false,
    addUserModal: false,
}

export const modalsReducer: Reducer<ModalsState> = (state = initialModalsState, action) => {
    switch (action.type) {
        case ModalActionTypes.OPEN_CREATE_EVENT_MODAL_REQUESTED:
            return {...state, createEventModal: true}

        case ModalActionTypes.CLOSE_CREATE_EVENT_MODAL_REQUESTED:
            return {...state, createEventModal: false}

        case ModalActionTypes.OPEN_UPDATE_EVENT_MODAL_REQUESTED:
            return {...state, updateEventModal: {opened: true, event: action.payload.event}}

        case ModalActionTypes.CLOSE_UPDATE_EVENT_MODAL_REQUESTED:
            return {...state, updateEventModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_PARTICIPANT_MODAL_REQUESTED:
            return {...state, addParticipantModal: {opened: true, event: action.payload.event}}

        case ModalActionTypes.CLOSE_ADD_PARTICIPANT_MODAL_REQUESTED:
            return {...state, addParticipantModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_ANNOTATION_MODAL_REQUESTED:
            return {...state, addAnnotationModal: {opened: true, event: action.payload.event}}

        case ModalActionTypes.CLOSE_ADD_ANNOTATION_MODAL_REQUESTED:
            return {...state, addAnnotationModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_PERMISSION_MODAL_REQUESTED:
            return {...state, addPermissionModal: true}

        case ModalActionTypes.CLOSE_ADD_PERMISSION_MODAL_REQUESTED:
            return {...state, addPermissionModal: false}

        case ModalActionTypes.OPEN_UPDATE_PERMISSION_MODAL_REQUESTED:
            return {...state, updatePermissionModal: {opened: true, permission: action.payload.permission}}

        case ModalActionTypes.CLOSE_UPDATE_PERMISSION_MODAL_REQUESTED:
            return {...state, updatePermissionModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_FILE_TO_PERMISSION_MODAL_REQUESTED:
            return {...state, addFileToPermissionModal: {opened: true, permission: action.payload.permission}}

        case ModalActionTypes.CLOSE_ADD_FILE_TO_PERMISSION_MODAL_REQUESTED:
            return {...state, addFileToPermissionModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_USER_TO_PERMISSION_MODAL_REQUESTED:
            return {...state, addUserToPermissionModal: {opened: true, permission: action.payload.permission}}

        case ModalActionTypes.CLOSE_ADD_USER_TO_PERMISSION_MODAL_REQUESTED:
            return {...state, addUserToPermissionModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED:
            return {...state, addEventToPermissionModal: {opened: true, permission: action.payload.permission}}

        case ModalActionTypes.CLOSE_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED:
            return {...state, addEventToPermissionModal: {opened: false}}

        case ModalActionTypes.OPEN_ADD_REFERENCE_MODAL_REQUESTED:
            return {...state, addReferenceModal: true}

        case ModalActionTypes.CLOSE_ADD_REFERENCE_MODAL_REQUESTED:
            return {...state, addReferenceModal: false}

        case ModalActionTypes.OPEN_ADD_TIP_MODAL_REQUESTED:
            return {...state, addTipModal: true}

        case ModalActionTypes.CLOSE_ADD_TIP_MODAL_REQUESTED:
            return {...state, addTipModal: false}

        case ModalActionTypes.OPEN_ADD_INFO_MODAL_REQUESTED:
            return {...state, addInfoModal: true}

        case ModalActionTypes.CLOSE_ADD_INFO_MODAL_REQUESTED:
            return {...state, addInfoModal: false}

        case ModalActionTypes.OPEN_ADD_USER_MODAL_REQUESTED:
            return {...state, addUserModal: true}

        case ModalActionTypes.CLOSE_ADD_USER_MODAL_REQUESTED:
            return {...state, addUserModal: false}

        default:
            return state
    }
}