import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {closeAddEventToPermissionModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import SelectSearch from 'react-select-search';
import {Permission} from "../../../api/permissions";
import {addEventRequested, permissionsRequestFailed} from "../../../store/permissions/actions";
import {Event} from "../../../api/events";
import {allEventsRequested} from "../../../store/events/action";

const AddEventToPermissionModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [selectedEventId, setSelectedEventId] = useState()
    const [permission, setPermission] = useState<Permission>(store.getState().modals.addEventToPermissionModal.permission)
    const [showModal, setShowModal] = useState(store.getState().modals.addEventToPermissionModal.opened)

    const [events, setEvents] = useState<Event[]>(store.getState().events.data)

    const closeModal = () => {
        setSelectedEventId(undefined)
        dispatch(closeAddEventToPermissionModalRequested())
    }

    const dispatchError = () => dispatch(permissionsRequestFailed('Nicht Angemeldet'))
    const addEvent = () => {
        if (selectedEventId !== undefined) {
            // @ts-ignore
            dispatchAction(token, token => dispatch(addEventRequested({permissionId: permission.id, eventId: selectedEventId}, token)), dispatchError)
        }
    }

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        if (token !== undefined) dispatch(allEventsRequested(token))
        return store.subscribe(() => {
            const state = store.getState()
            setPermission(state.modals.addEventToPermissionModal.permission)
            setShowModal(state.modals.addEventToPermissionModal.opened)
            if (!state.events.loading) setEvents(state.events.data)
        })
    }, [dispatch, store, token])

    return permission
        ? <Modal show={showModal} onHide={closeModal}
                 centered={true} className="add-Event-to-permission-modal">
            <Modal.Header closeButton>
                <Modal.Title>Kurs hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectSearch
                    options={events.map(event => ({name: `${event.title} - ${event.subtitle}`, value: event.id}))}
                    value={selectedEventId}
                    onChange={(Event: any) => setSelectedEventId(Event)}
                    placeholder="Kurs auswählen"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    addEvent()
                    closeModal()
                }}>
                    Hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
        : <></>
}

export default AddEventToPermissionModal