import {call, takeEvery} from "redux-saga/effects";
import {getDeleteRequestHandler, getRequestHandler} from "../common";
import {PermissionsActionTypes} from "./types";
import {
    addEventRequested,
    addEventSucceeded,
    addPermissionRequested,
    addPermissionSucceeded,
    addFileRequested,
    addFileSucceeded,
    addUserRequested,
    addUserSucceeded,
    allPermissionsRequested,
    allPermissionsSucceeded,
    deletedPermissionRequested,
    deletedPermissionSucceeded,
    permissionsRequestFailed,
    removeEventRequested,
    removeEventSucceeded,
    removeFileRequested,
    removeFileSucceeded,
    removeUserRequested,
    removeUserSucceeded,
    updatedPermissionRequested,
    updatedPermissionSucceeded
} from "./actions";
import {ApiPermissions} from "../../api/permissions";

export default function* watchPermissionRequests() {
    yield takeEvery(PermissionsActionTypes.ADD_PERMISSION_REQUESTED, getRequestHandler<typeof addPermissionRequested>(
        addPermissionSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.addPermission, action.payload.permissionData, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.ALL_PERMISSIONS_REQUESTED, getRequestHandler<typeof allPermissionsRequested>(
        allPermissionsSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.getPermissions, action.payload)
    ))
    yield takeEvery(PermissionsActionTypes.ADD_USER_REQUESTED, getRequestHandler<typeof addUserRequested>(
        addUserSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.addUserToPermission, action.payload.data, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.REMOVE_USER_REQUESTED, getRequestHandler<typeof removeUserRequested>(
        removeUserSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.removeUserFromPermission, action.payload.data, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.ADD_EVENT_REQUESTED, getRequestHandler<typeof addEventRequested>(
        addEventSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.addEventToPermission, action.payload.data, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.REMOVE_EVENT_REQUESTED, getRequestHandler<typeof removeEventRequested>(
        removeEventSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.removeEventFromPermission, action.payload.data, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.ADD_FILE_REQUESTED, getRequestHandler<typeof addFileRequested>(
        addFileSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.addResourceToPermission, action.payload.data, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.REMOVE_FILE_REQUESTED, getRequestHandler<typeof removeFileRequested>(
        removeFileSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.removeResourceFromPermission, action.payload.data, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.UPDATED_PERMISSION_REQUESTED, getRequestHandler<typeof updatedPermissionRequested>(
        updatedPermissionSucceeded, permissionsRequestFailed,
        (action) => call(ApiPermissions.updatePermission, action.payload.permission, action.payload.token)
    ))
    yield takeEvery(PermissionsActionTypes.DELETED_PERMISSION_REQUESTED, getDeleteRequestHandler<typeof deletedPermissionRequested>(
        (action) => deletedPermissionSucceeded(action.payload.permissionId),
        permissionsRequestFailed,
        (action) => call(ApiPermissions.deletePermission, action.payload.permissionId, action.payload.token)
    ))
}