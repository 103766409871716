import {Reducer} from "redux";
import {InfosActionTypes, InfosState, ReferencesActionTypes, ReferencesState, TipsActionTypes, TipsState} from "./types";
import {ReferenceItem} from "../../api/references";
import {InfoItem} from "../../api/infos";

const initialInfoState: InfosState = {
    data: [],
    errors: undefined,
    loading: false,
}

const initialTipState: TipsState = {
    data: [],
    errors: undefined,
    loading: false,
}

const initialReferenceState: ReferencesState = {
    data: [],
    errors: undefined,
    loading: false,
}

export const infosReducer: Reducer<InfosState> = (state = initialInfoState, action) => {
    switch (action.type) {
        case InfosActionTypes.ADD_INFO_REQUESTED:
        case InfosActionTypes.ALL_INFOS_REQUESTED:
        case InfosActionTypes.UPDATED_INFO_REQUESTED:
        case InfosActionTypes.DELETED_INFO_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case InfosActionTypes.ADD_INFO_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case InfosActionTypes.ALL_INFOS_SUCCEEDED:
            return {...state, loading: false, data: action.payload.sort((first: InfoItem, second: InfoItem) => first.position - second.position), errors: undefined}

        case InfosActionTypes.UPDATED_INFO_SUCCEEDED:
            const updatedInfos = state.data
                .map(info => info.id === action.payload.id ? action.payload : info)
                .sort((first, second) => first.position - second.position)
            return {...state, loading: false, data: updatedInfos}

        case InfosActionTypes.DELETED_INFO_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(info => info.id !== action.payload)}

        case InfosActionTypes.INFOS_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}

export const tipsReducer: Reducer<TipsState> = (state = initialTipState, action) => {
    switch (action.type) {
        case TipsActionTypes.ADD_TIP_REQUESTED:
        case TipsActionTypes.ALL_TIPS_REQUESTED:
        case TipsActionTypes.UPDATED_TIP_REQUESTED:
        case TipsActionTypes.DELETED_TIP_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case TipsActionTypes.ADD_TIP_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case TipsActionTypes.ALL_TIPS_SUCCEEDED:
            return {...state, loading: false, data: action.payload, errors: undefined}

        case TipsActionTypes.UPDATED_TIP_SUCCEEDED:
            return {...state, loading: false, data: state.data.map(tip => tip.id === action.payload.id ? action.payload : tip)}

        case TipsActionTypes.DELETED_TIP_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(tip => tip.id !== action.payload)}

        case TipsActionTypes.TIPS_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}

export const referencesReducer: Reducer<ReferencesState> = (state = initialReferenceState, action) => {
    switch (action.type) {
        case ReferencesActionTypes.ADD_REFERENCE_REQUESTED:
        case ReferencesActionTypes.ALL_REFERENCES_REQUESTED:
        case ReferencesActionTypes.UPDATED_REFERENCE_REQUESTED:
        case ReferencesActionTypes.DELETED_REFERENCE_REQUESTED:
            return {...state, loading: true, errors: undefined}

        case ReferencesActionTypes.ADD_REFERENCE_SUCCEEDED:
            return {...state, loading: false, data: [...state.data, action.payload], errors: undefined}

        case ReferencesActionTypes.ALL_REFERENCES_SUCCEEDED:
            return {...state, loading: false, data: action.payload.sort((first: ReferenceItem, second: ReferenceItem) => first.position - second.position), errors: undefined}

        case ReferencesActionTypes.UPDATED_REFERENCE_SUCCEEDED:
            const updatedReferences = state.data
                .map(reference => reference.id === action.payload.id ? action.payload : reference)
                .sort((first, second) => first.position - second.position)
            return {...state, loading: false, data: updatedReferences}

        case ReferencesActionTypes.DELETED_REFERENCE_SUCCEEDED:
            return {...state, loading: false, data: state.data.filter(reference => reference.id !== action.payload)}

        case ReferencesActionTypes.REFERENCES_REQUEST_FAILED:
            return {...state, loading: false, errors: action.payload}

        default:
            return state
    }
}