import {BaseState} from "../common";
import {File} from "../../api/files";

export enum FileActionTypes {
    UPLOAD_FILE_REQUESTED = '@@files/UPLOAD_FILE_REQUESTED',
    UPLOAD_FILE_SUCCEEDED = '@@files/UPLOAD_FILE_SUCCEEDED',
    ALL_FILES_REQUESTED = '@@files/ALL_FILES_REQUESTED',
    ALL_FILES_SUCCEEDED = '@@files/ALL_FILES_SUCCEEDED',
    UPDATED_FILE_REQUESTED = '@@files/UPDATE_FILE_REQUESTED',
    UPDATED_FILE_SUCCEEDED = '@@files/UPDATE_FILE_SUCCEEDED',
    DELETED_FILE_REQUESTED = '@@files/DELETED_FILE_REQUESTED',
    DELETED_FILE_SUCCEEDED = '@@files/DELETED_FILE_SUCCEEDED',
    FILES_REQUEST_FAILED = '@@files/FILES_REQUEST_FAILED',
}


export interface FilesState extends BaseState {
    readonly data: File[]
}