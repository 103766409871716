import {dispatchAction} from "../../common";
import React, {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {closeAddInfoModalRequested} from "../../../store/modals/actions";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {addInfoRequested, infosRequestFailed} from "../../../store/index-content/actions";
import {InfoItem} from "../../../api/infos";


const AddInfoModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [text, setText] = useState<string>("")
    const [infos, setInfos] = useState<InfoItem[]>(store.getState().infos.data)
    const [showModal, setShowModal] = useState(store.getState().modals.addInfoModal)

    const closeModal = () => {
        setText("")
        dispatch(closeAddInfoModalRequested())
    }

    const dispatchError = () => dispatch(infosRequestFailed('Nicht Angemeldet'))
    const addInfo = () => {
        dispatchAction(token, token => dispatch(addInfoRequested({text, position: infos.length}, token)), dispatchError)
    }

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            setInfos(state.infos.data)
            setShowModal(state.modals.addInfoModal)
        })
    }, [dispatch, store, token])

    return <Modal show={showModal} onHide={closeModal} centered={true} className="create-info-modal">
        <Modal.Header closeButton>
            <Modal.Title>Info hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Container>
                    <Row>
                        <Col md={2}>Text</Col>
                        <Col md={10}>
                            <input value={text} onChange={event => setText(event.target.value)}/>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Schließen
            </Button>
            <Button variant="primary" onClick={() => {
                if (text === "")
                    return alert("Felder nicht vollständig ausgefüllt")
                addInfo()
                closeModal()
            }}>
                Hinzufügen
            </Button>
        </Modal.Footer>
    </Modal>
}

export default AddInfoModal