import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {closeAddFileToPermissionModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import SelectSearch from 'react-select-search';
import {File} from "../../../api/files";
import {Permission} from "../../../api/permissions";
import {addFileRequested, permissionsRequestFailed} from "../../../store/permissions/actions";
import {allFilesRequested} from "../../../store/files/action";
import {getFileIcon} from "../common/helpers";

const AddFileToPermissionModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [selectedFileId, setSelectedFileId] = useState<number | undefined>()
    const [permission, setPermission] = useState<Permission>(store.getState().modals.addFileToPermissionModal.permission)
    const [showModal, setShowModal] = useState(store.getState().modals.addFileToPermissionModal.opened)

    const [files, setFiles] = useState<File[]>(store.getState().files.data)

    const closeModal = () => {
        setSelectedFileId(undefined)
        dispatch(closeAddFileToPermissionModalRequested())
    }

    const dispatchError = () => dispatch(permissionsRequestFailed('Nicht Angemeldet'))
    const addFile = () => {
        if (selectedFileId) {
            dispatchAction(token, token => dispatch(addFileRequested({permissionId: permission.id, fileId: selectedFileId}, token)), dispatchError)
        }
    }

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        if (token !== undefined) dispatch(allFilesRequested(token))
        return store.subscribe(() => {
            const state = store.getState()
            setPermission(state.modals.addFileToPermissionModal.permission)
            setShowModal(state.modals.addFileToPermissionModal.opened)
            if (!state.files.loading) setFiles(state.files.data)
        })
    }, [dispatch, store, token])

    return permission
        ? <Modal show={showModal} onHide={closeModal}
                 centered={true} className="add-resource-to-permission-modal">
            <Modal.Header closeButton>
                <Modal.Title>Anmerkung hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectSearch
                    options={files.map(file => ({
                        name: `${getFileIcon(file.type)} ${file.name}`,
                        value: file.id
                    }))}
                    value={selectedFileId?.toString()}
                    onChange={option => setSelectedFileId(parseInt(option.toString()))}
                    placeholder="Datei auswählen"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    addFile()
                    closeModal()
                }}>
                    Hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
        : <></>
}

export default AddFileToPermissionModal