import React, {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {dispatchAction} from "../../common";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {File as FileResource, FileType} from "../../../api/files";
import {
    allFilesRequested,
    deletedFileRequested,
    filesRequestFailed,
    updatedFileRequested,
    uploadFileRequested
} from "../../../store/files/action";
import {askBefore, getFileSymbol} from "../common/helpers";

interface IProps {
    token: string | undefined
}

const FilesCard: React.FC<IProps> = ({token}) => {
    const store = useStore()
    const dispatch = useDispatch()

    const [files, setFiles] = useState<FileResource[]>(store.getState().files.data)

    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const [isFilePicked, setIsFilePicked] = useState<boolean>(false);

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            if (!state.files.loading) setFiles(state.files.data)
        })
    }, [store])

    useEffect(() => {
        if (token !== undefined) dispatch(allFilesRequested(token))
    }, [dispatch, token])

    const dispatchError = () => dispatch(filesRequestFailed('Nicht Angemeldet'))

    const getResourceTypeFromSelectedFile = () => {
        if (selectedFile) {
            switch (selectedFile.type) {
                case 'application/pdf':
                    return FileType.DOCUMENT
                case 'audio/mpeg':
                    return FileType.RECORDING
                default:
                    alert('Datei-Typ nicht unterstützt')
            }
        }
    }

    const uploadResource = () => dispatchAction(token, token => {
        if (isFilePicked && selectedFile !== undefined) {
            const fileType = getResourceTypeFromSelectedFile()
            if (fileType) {
                dispatch(uploadFileRequested({file: selectedFile, filename: selectedFileName, fileType: fileType}, token))
                setIsFilePicked(false)
                setSelectedFileName('')
            }
        }
    }, dispatchError)
    const updateFile = (file: FileResource) => dispatchAction(token, token => dispatch(updatedFileRequested(file, token)), dispatchError)
    const deleteFile = (fileId: number) => {
        askBefore(
            "Willst du die Datei wirklich löschen?",
            () => dispatchAction(token, token => dispatch(deletedFileRequested(fileId, token)), dispatchError)
        )
    }

    return <Card>
        <Card.Header as="h5">Dateien</Card.Header>
        <Card.Body>
            <Container className="file-upload">
                <Row>
                    <Col md={2}>
                        <span>Upload:</span>
                    </Col>
                    <Col md={3}>
                        <input value={selectedFileName} placeholder={'Dokument-Name'}
                               onChange={event => setSelectedFileName(event.target.value)}/>
                    </Col>
                    <Col md={4}>
                        {isFilePicked ? <span className="filename">{selectedFile?.name}</span> :
                            <span className="filename">keine Datei ausgewählt</span>}
                        <input type="file" name="file" ref={hiddenFileInput} onChange={(event) => {
                            if (event.target.files) {
                                setSelectedFile(event.target.files[0]);
                                setIsFilePicked(true);
                            }
                        }}/>
                    </Col>
                    <Col md={3}>
                        <Button onClick={() => {
                            if (isFilePicked) {
                                return uploadResource()
                            } else if (hiddenFileInput && hiddenFileInput.current) {
                                hiddenFileInput.current.click();
                            }
                        }}>
                            {isFilePicked ? <>hochladen</> : <>Datei auswählen</>}
                        </Button>
                    </Col>
                </Row>
            </Container>
            <div className="content-list">
                {files.length > 0
                    ? <table>
                        <tbody>
                        {
                            files.map(file => {
                                return (
                                    <tr key={file.id} className="hover-item file">
                                        <td width="95%">
                                            {getFileSymbol(file.type)}
                                            <span contentEditable={true} onBlur={event => updateFile({...file, name: event.target.innerText})}>
                                            {file.name}
                                        </span>
                                        </td>
                                        <td width="5%" className="icon-column">
                                            <FontAwesomeIcon icon={faTrash} focusable={true} className="delete-icon" onClick={() => deleteFile(file.id)}/>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                    : <span className="no-data">keine Dateien vorhanden</span>}
            </div>
        </Card.Body>
    </Card>
}

export default FilesCard