import {action} from "typesafe-actions";
import {FileActionTypes} from "./types";
import {File, FileUploadData} from "../../api/files";

export const uploadFileRequested = (fileData: FileUploadData, token: string) => action(FileActionTypes.UPLOAD_FILE_REQUESTED, {fileData, token})
export const uploadFileSucceeded = (file: File) => action(FileActionTypes.UPLOAD_FILE_SUCCEEDED, file)
export const allFilesRequested = (token: string) => action(FileActionTypes.ALL_FILES_REQUESTED, token)
export const allFilesSucceeded = (files: File[]) => action(FileActionTypes.ALL_FILES_SUCCEEDED, files)
export const updatedFileRequested = (file: File, token: string) => action(FileActionTypes.UPDATED_FILE_REQUESTED, {file, token})
export const updatedFileSucceeded = (file: File) => action(FileActionTypes.UPDATED_FILE_SUCCEEDED, file)
export const deletedFileRequested = (fileId: number, token: string) => action(FileActionTypes.DELETED_FILE_REQUESTED, {fileId, token})
export const deletedFileSucceeded = (fileId: number) => action(FileActionTypes.DELETED_FILE_SUCCEEDED, fileId)
export const filesRequestFailed = (message: string) => action(FileActionTypes.FILES_REQUEST_FAILED, message)
