import {action} from "typesafe-actions";
import {ModalActionTypes} from "./types";
import {Event} from "../../api/events";
import {Permission} from "../../api/permissions";

export const openCreateEventModalRequested = () => action(ModalActionTypes.OPEN_CREATE_EVENT_MODAL_REQUESTED)
export const closeCreateEventModalRequested = () => action(ModalActionTypes.CLOSE_CREATE_EVENT_MODAL_REQUESTED)

export const openUpdateEventModalRequested = (event: Event) => action(ModalActionTypes.OPEN_UPDATE_EVENT_MODAL_REQUESTED, {event})
export const closeUpdateEventModalRequested = () => action(ModalActionTypes.CLOSE_UPDATE_EVENT_MODAL_REQUESTED)

export const openAddParticipantModalRequested = (event: Event) => action(ModalActionTypes.OPEN_ADD_PARTICIPANT_MODAL_REQUESTED, {event})
export const closeAddParticipantModalRequested = () => action(ModalActionTypes.CLOSE_ADD_PARTICIPANT_MODAL_REQUESTED)

export const openAddAnnotationModalRequested = (event: Event) => action(ModalActionTypes.OPEN_ADD_ANNOTATION_MODAL_REQUESTED, {event})
export const closeAddAnnotationModalRequested = () => action(ModalActionTypes.CLOSE_ADD_ANNOTATION_MODAL_REQUESTED)

export const openAddPermissionModalRequested = () => action(ModalActionTypes.OPEN_ADD_PERMISSION_MODAL_REQUESTED)
export const closeAddPermissionModalRequested = () => action(ModalActionTypes.CLOSE_ADD_PERMISSION_MODAL_REQUESTED)

export const openUpdatePermissionModalRequested = (permission: Permission) => action(ModalActionTypes.OPEN_UPDATE_PERMISSION_MODAL_REQUESTED, {permission})
export const closeUpdatePermissionModalRequested = () => action(ModalActionTypes.CLOSE_UPDATE_PERMISSION_MODAL_REQUESTED)

export const openAddResourceToPermissionModalRequested = (permission: Permission) => action(ModalActionTypes.OPEN_ADD_FILE_TO_PERMISSION_MODAL_REQUESTED, {permission})
export const closeAddFileToPermissionModalRequested = () => action(ModalActionTypes.CLOSE_ADD_FILE_TO_PERMISSION_MODAL_REQUESTED)

export const openAddUserToPermissionModalRequested = (permission: Permission) => action(ModalActionTypes.OPEN_ADD_USER_TO_PERMISSION_MODAL_REQUESTED, {permission})
export const closeAddUserToPermissionModalRequested = () => action(ModalActionTypes.CLOSE_ADD_USER_TO_PERMISSION_MODAL_REQUESTED)

export const openAddEventToPermissionModalRequested = (permission: Permission) => action(ModalActionTypes.OPEN_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED, {permission})
export const closeAddEventToPermissionModalRequested = () => action(ModalActionTypes.CLOSE_ADD_EVENT_TO_PERMISSION_MODAL_REQUESTED)

export const openAddReferenceModalRequested = () => action(ModalActionTypes.OPEN_ADD_REFERENCE_MODAL_REQUESTED)
export const closeAddReferenceModalRequested = () => action(ModalActionTypes.CLOSE_ADD_REFERENCE_MODAL_REQUESTED)

export const openAddTipModalRequested = () => action(ModalActionTypes.OPEN_ADD_TIP_MODAL_REQUESTED)
export const closeAddTipModalRequested = () => action(ModalActionTypes.CLOSE_ADD_TIP_MODAL_REQUESTED)

export const openAddInfoModalRequested = () => action(ModalActionTypes.OPEN_ADD_INFO_MODAL_REQUESTED)
export const closeAddInfoModalRequested = () => action(ModalActionTypes.CLOSE_ADD_INFO_MODAL_REQUESTED)

export const openAddUserModalRequested = () => action(ModalActionTypes.OPEN_ADD_USER_MODAL_REQUESTED)
export const closeAddUserModalRequested = () => action(ModalActionTypes.CLOSE_ADD_USER_MODAL_REQUESTED)