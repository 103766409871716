import React, {useEffect, useState} from 'react';
import Header from "../components/header/header";
import Navigation from "../components/navigation/navigation";
import Login from "../components/login/login";
import {Col, Container, Row} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {Route, Switch} from "react-router-dom";
import jwt_decode, {JwtPayload} from "jwt-decode";
import LandingPage from "./content/landing-page";
import EventsPage from "./content/events-page";
import UsersPage from "./content/users-page";
import NotFound from "./content/not-found";
import {loginSucceeded} from "../store/admin/actions";
import FilesPage from "./content/files-page";
import './skeleton.css';
import PermissionsPage from "./content/permissions-page";

const Skeleton = () => {
    const [showLoginPopup, setShowLoginPopup] = useState(false)
    const store = useStore()
    const dispatch = useDispatch()

    const isTokenValid = (token: string | undefined): boolean => {
        if (token === undefined) return false
        const tokenPayload = jwt_decode<JwtPayload>(token)
        if (tokenPayload.exp) {
            const currentTime = new Date()
            const tokenExpires = new Date(tokenPayload.exp * 1000)
            return currentTime < tokenExpires
        }
        return false
    }

    useEffect(() => {
        const tryToLoadStoredToken = () => {
            const storedToken = localStorage.getItem('accessToken')
            if (storedToken && isTokenValid(storedToken)) {
                dispatch(loginSucceeded({access_token: storedToken, token_type: 'bearer'}))
                return storedToken
            }
        }

        const storedToken = tryToLoadStoredToken()
        setShowLoginPopup(storedToken === undefined)

        return store.subscribe(() => {
            const state = store.getState()
            const validTokenAvailable = isTokenValid(state.admin.data ? state.admin.data.access_token : storedToken)
            setShowLoginPopup(!validTokenAvailable)
        })
    }, [dispatch, store])

    return (
        <>
            {showLoginPopup ? <Login/> : <></>}
            <div className={`page ${showLoginPopup ? 'deactivated' : ''}`}>
                <Header/>
                <Container fluid className='content-container background-primary'>
                    <Row>
                        <Col sm={12} lg={3}>
                            <Navigation/>
                        </Col>
                        <Col sm={12} lg={9}>
                            <main>
                                <Switch>
                                    <Route path="/startseite" exact component={LandingPage}/>
                                    <Route path="/user" exact component={UsersPage}/>
                                    <Route path="/kurse" exact component={EventsPage}/>
                                    <Route path="/dateien" exact component={FilesPage}/>
                                    <Route path="/berechtigungen" exact component={PermissionsPage}/>
                                    <Route path="/" exact component={LandingPage}/>
                                    <Route path="*" component={NotFound}/>
                                </Switch>
                            </main>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Skeleton;
