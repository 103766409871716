import {ApiBase} from "./base";
import {Permission} from "./permissions";

export enum FileType {
    DOCUMENT = 'DOCUMENT',
    RECORDING = 'RECORDING',
}

export type File = {
    id: number
    name: string
    path: string
    type: FileType
    uploaded_at: Date
    permissions?: Permission[]
}

export type FileUploadData = {
    file: Blob
    filename: string
    fileType: FileType
}

export class ApiFiles extends ApiBase {
    public static async getFiles(token: string) {
        return ApiFiles.getRequest('/files/', token)
    }

    public static async getFile(fileId: number, token: string) {
        return ApiFiles.getRequest(`/files/${fileId}`, token)
    }

    public static async uploadFile(fileData: FileUploadData, token: string) {
        const form = new FormData()
        form.append('file', fileData.file)

        const configs: RequestInit = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: form,
        }

        const response = await fetch(`${ApiBase.base_url}/files/?name=${fileData.filename}&file_type=${fileData.fileType}`, configs)
        return response.json()
    }

    public static async updateFile(file: File, token: string) {
        return ApiFiles.putRequest(`/files/${file.id}`, file, token)
    }

    public static async deleteFile(fileId: number, token: string) {
        return ApiFiles.deleteRequest(`/files/${fileId}`, token)
    }
}
