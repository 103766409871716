import {action} from "typesafe-actions";
import {UsersActionTypes} from "./types";
import {CreateUserInput, User} from "../../api/user";

export const addUserRequested = (userData: CreateUserInput, token: string) => action(UsersActionTypes.ADD_USER_REQUESTED, {userData, token})
export const addUserSucceeded = (user: User) => action(UsersActionTypes.ADD_USER_SUCCEEDED, user)
export const allUsersRequested = (token: string) => action(UsersActionTypes.ALL_USERS_REQUESTED, token)
export const allUsersSucceeded = (users: User[]) => action(UsersActionTypes.ALL_USERS_SUCCEEDED, users)
export const updatedUserRequested = (user: User, token: string) => action(UsersActionTypes.UPDATED_USER_REQUESTED, {user, token})
export const updatedUserSucceeded = (data: User) => action(UsersActionTypes.UPDATED_USER_SUCCEEDED, data)
export const deletedUserRequested = (userId: number, token: string) => action(UsersActionTypes.DELETED_USER_REQUESTED, {userId, token})
export const deletedUserSucceeded = (userId: number) => action(UsersActionTypes.DELETED_USER_SUCCEEDED, userId)
export const usersRequestFailed = (message: string) => action(UsersActionTypes.USERS_REQUEST_FAILED, message)
