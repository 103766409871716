import {Store} from "redux";
import React, {useEffect} from "react";

export const useSetTokenEffect = (store: Store, setToken: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    return useEffect(() => {
        const setTokenIfAvailable = () => {
            const state = store.getState()
            setToken(state.admin.data ? state.admin.data.access_token : undefined)
        }

        setTokenIfAvailable()
        return store.subscribe(setTokenIfAvailable)
    }, [setToken, store])
}
