import {ApiBase} from "./base";

export type InfoItem = {
    id: number
    text: string
    position: number
}


export type CreateInfoInput = {
    text: string
    position: number
}


export class ApiInfos extends ApiBase {
    public static async getInfos(token: string) {
        return ApiInfos.getRequest('/infos/', token)
    }

    public static async getInfo(infoId: number, token: string) {
        return ApiInfos.getRequest(`/infos/${infoId}`, token)
    }

    public static async addInfo(infoInput: CreateInfoInput, token: string) {
        return ApiInfos.postRequest('/infos/', infoInput, token)
    }

    public static async updateInfo(info: InfoItem, token: string) {
        return ApiInfos.putRequest(`/infos/${info.id}`, info, token)
    }

    public static async deleteInfo(infoId: number, token: string) {
        return ApiInfos.deleteRequest(`/infos/${infoId}`, token)
    }
}
