import {ApiBase} from "./base";

export type TipItem = {
    id: number
    text: string
}

export class ApiTips extends ApiBase {
    public static async getTips(token: string) {
        return ApiTips.getRequest('/tips/', token)
    }

    public static async getTip(tipId: number, token: string) {
        return ApiTips.getRequest(`/tips/${tipId}`, token)
    }

    public static async addTip(tipText: string, token: string) {
        const body = {text: tipText}
        return ApiTips.postRequest('/tips/', body, token)
    }

    public static async updateTip(tip: TipItem, token: string) {
        return ApiTips.putRequest(`/tips/${tip.id}`, tip, token)
    }

    public static async deleteTip(tipId: number, token: string) {
        return ApiTips.deleteRequest(`/tips/${tipId}`, token)
    }
}