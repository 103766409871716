import {all, call, fork, takeEvery} from "redux-saga/effects";
import {
    addInfoRequested,
    addInfoSucceeded,
    addReferenceRequested,
    addReferenceSucceeded,
    addTipRequested,
    addTipSucceeded,
    allInfosRequested,
    allInfosSucceeded,
    allReferencesRequested,
    allReferencesSucceeded,
    allTipsRequested,
    allTipsSucceeded,
    deletedInfoRequested,
    deletedInfoSucceeded,
    deletedReferenceRequested,
    deletedReferenceSucceeded,
    deletedTipRequested,
    deletedTipSucceeded,
    infosRequestFailed,
    referencesRequestFailed,
    tipsRequestFailed,
    updatedInfoRequested,
    updatedInfoSucceeded,
    updatedReferenceRequested,
    updatedReferenceSucceeded,
    updatedTipRequested,
    updatedTipSucceeded
} from "./actions";
import {InfosActionTypes, ReferencesActionTypes, TipsActionTypes} from "./types";
import {getDeleteRequestHandler, getRequestHandler} from "../common";
import {ApiInfos} from "../../api/infos";
import {ApiTips} from "../../api/tips";
import {ApiReferences} from "../../api/references";

function* watchInfoRequests() {
    yield takeEvery(InfosActionTypes.ADD_INFO_REQUESTED, getRequestHandler<typeof addInfoRequested>(
        addInfoSucceeded, infosRequestFailed,
        (action) => call(ApiInfos.addInfo, action.payload.infoInput, action.payload.token)
    ))
    yield takeEvery(InfosActionTypes.ALL_INFOS_REQUESTED, getRequestHandler<typeof allInfosRequested>(
        allInfosSucceeded, infosRequestFailed,
        (action) => call(ApiInfos.getInfos, action.payload)
    ))
    yield takeEvery(InfosActionTypes.UPDATED_INFO_REQUESTED, getRequestHandler<typeof updatedInfoRequested>(
        updatedInfoSucceeded, infosRequestFailed,
        (action) => call(ApiInfos.updateInfo, action.payload.info, action.payload.token)
    ))
    yield takeEvery(InfosActionTypes.DELETED_INFO_REQUESTED, getDeleteRequestHandler<typeof deletedInfoRequested>(
        (action) => deletedInfoSucceeded(action.payload.infoId),
        infosRequestFailed,
        (action) => call(ApiInfos.deleteInfo, action.payload.infoId, action.payload.token)
    ))
}

function* watchTipRequests() {
    yield takeEvery(TipsActionTypes.ADD_TIP_REQUESTED, getRequestHandler<typeof addTipRequested>(
        addTipSucceeded, tipsRequestFailed,
        (action) => call(ApiTips.addTip, action.payload.text, action.payload.token)
    ))
    yield takeEvery(TipsActionTypes.ALL_TIPS_REQUESTED, getRequestHandler<typeof allTipsRequested>(
        allTipsSucceeded, tipsRequestFailed,
        (action) => call(ApiTips.getTips, action.payload)
    ))
    yield takeEvery(TipsActionTypes.UPDATED_TIP_REQUESTED, getRequestHandler<typeof updatedTipRequested>(
        updatedTipSucceeded, tipsRequestFailed,
        (action) => call(ApiTips.updateTip, action.payload.tip, action.payload.token)
    ))
    yield takeEvery(TipsActionTypes.DELETED_TIP_REQUESTED, getDeleteRequestHandler<typeof deletedTipRequested>(
        (action) => deletedTipSucceeded(action.payload.tipId),
        tipsRequestFailed,
        (action) => call(ApiTips.deleteTip, action.payload.tipId, action.payload.token)
    ))
}

function* watchReferenceRequests() {
    yield takeEvery(ReferencesActionTypes.ADD_REFERENCE_REQUESTED, getRequestHandler<typeof addReferenceRequested>(
        addReferenceSucceeded, referencesRequestFailed,
        (action) => call(ApiReferences.addReference, action.payload.referenceInput, action.payload.token)
    ))
    yield takeEvery(ReferencesActionTypes.ALL_REFERENCES_REQUESTED, getRequestHandler<typeof allReferencesRequested>(
        allReferencesSucceeded, referencesRequestFailed,
        (action) => call(ApiReferences.getReferences, action.payload)
    ))
    yield takeEvery(ReferencesActionTypes.UPDATED_REFERENCE_REQUESTED, getRequestHandler<typeof updatedReferenceRequested>(
        updatedReferenceSucceeded, referencesRequestFailed,
        (action) => call(ApiReferences.updateReference, action.payload.reference, action.payload.token)
    ))
    yield takeEvery(ReferencesActionTypes.DELETED_REFERENCE_REQUESTED, getDeleteRequestHandler<typeof deletedReferenceRequested>(
        (action) => deletedReferenceSucceeded(action.payload.referenceId),
        referencesRequestFailed,
        (action) => call(ApiReferences.deleteReference, action.payload.referenceId, action.payload.token)
    ))
}

export default function* indexContentSaga() {
    yield all([fork(watchInfoRequests), fork(watchTipRequests), fork(watchReferenceRequests)])
}
