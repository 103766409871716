import {BaseState} from "../common";
import {Permission} from "../../api/permissions";

export enum PermissionsActionTypes {
    ADD_PERMISSION_REQUESTED = '@@permissions/ADD_PERMISSION_REQUESTED',
    ADD_PERMISSION_SUCCEEDED = '@@permissions/ADD_PERMISSION_SUCCEEDED',
    ALL_PERMISSIONS_REQUESTED = '@@permissions/ALL_PERMISSIONS_REQUESTED',
    ALL_PERMISSIONS_SUCCEEDED = '@@permissions/ALL_PERMISSIONS_SUCCEEDED',
    ADD_USER_REQUESTED = '@@permissions/ADD_USER_REQUESTED',
    ADD_USER_SUCCEEDED = '@@permissions/ADD_USER_SUCCEEDED',
    REMOVE_USER_REQUESTED = '@@permissions/REMOVE_USER_REQUESTED',
    REMOVE_USER_SUCCEEDED = '@@permissions/REMOVE_USER_SUCCEEDED',
    ADD_EVENT_REQUESTED = '@@permissions/ADD_EVENT_REQUESTED',
    ADD_EVENT_SUCCEEDED = '@@permissions/ADD_EVENT_SUCCEEDED',
    REMOVE_EVENT_REQUESTED = '@@permissions/REMOVE_EVENT_REQUESTED',
    REMOVE_EVENT_SUCCEEDED = '@@permissions/REMOVE_EVENT_SUCCEEDED',
    ADD_FILE_REQUESTED = '@@permissions/ADD_RESOURCE_REQUESTED',
    ADD_FILE_SUCCEEDED = '@@permissions/ADD_RESOURCE_SUCCEEDED',
    REMOVE_FILE_REQUESTED = '@@permissions/REMOVE_RESOURCE_REQUESTED',
    REMOVE_FILE_SUCCEEDED = '@@permissions/REMOVE_RESOURCE_SUCCEEDED',
    UPDATED_PERMISSION_REQUESTED = '@@permissions/UPDATE_PERMISSION_REQUESTED',
    UPDATED_PERMISSION_SUCCEEDED = '@@permissions/UPDATE_PERMISSION_SUCCEEDED',
    DELETED_PERMISSION_REQUESTED = '@@permissions/DELETED_PERMISSION_REQUESTED',
    DELETED_PERMISSION_SUCCEEDED = '@@permissions/DELETED_PERMISSION_SUCCEEDED',
    PERMISSIONS_REQUEST_FAILED = '@@permissions/PERMISSIONS_REQUEST_FAILED',
}


export interface PermissionsState extends BaseState {
    readonly data: Permission[]
}