import {BaseState} from "../common";

export type AccessToken = {
    access_token: string
}

export enum AdminActionTypes {
    LOGIN_REQUEST = 'admin/LOGIN_REQUEST',
    LOGIN_SUCCESS = 'admin/LOGIN_SUCCESS',
    LOGIN_ERROR = 'admin/LOGIN_ERROR',
    LOGOUT = 'admin/LOGOUT',
}

export interface AdminState extends BaseState {
    readonly data: AccessToken | null
}