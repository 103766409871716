import {BaseState} from "../common";
import {User} from "../../api/user";

export enum UsersActionTypes {
    ADD_USER_REQUESTED = '@@users/ADD_USER_REQUESTED',
    ADD_USER_SUCCEEDED = '@@users/ADD_USER_SUCCEEDED',
    ALL_USERS_REQUESTED = '@@users/ALL_USERS_REQUESTED',
    ALL_USERS_SUCCEEDED = '@@users/ALL_USERS_SUCCEEDED',
    UPDATED_USER_REQUESTED = '@@users/UPDATE_USER_REQUESTED',
    UPDATED_USER_SUCCEEDED = '@@users/UPDATE_USER_SUCCEEDED',
    DELETED_USER_REQUESTED = '@@users/DELETED_USER_REQUESTED',
    DELETED_USER_SUCCEEDED = '@@users/DELETED_USER_SUCCEEDED',
    USERS_REQUEST_FAILED = '@@users/USERS_REQUEST_FAILED',
}


export interface UsersState extends BaseState {
    readonly data: User[]
}
