import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import Selection from "../common/select";
import {Event, EventBookingStatus, EventCategory} from "../../../api/events";
import {useDispatch, useStore} from "react-redux";
import {closeUpdateEventModalRequested} from "../../../store/modals/actions";
import {dispatchAction} from "../../common";
import {updatedEventRequested} from "../../../store/events/action";
import {infosRequestFailed} from "../../../store/index-content/actions";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {EventBookingStatusMapping} from "../cards/events-card";

const UpdateEventModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const [token, setToken] = useState<string>()
    const [event, setEvent] = useState<Event>(store.getState().modals.updateEventModal.event)
    const [showUpdateModal, setShowUpdateModal] = useState(store.getState().modals.updateEventModal.opened)

    const closeUpdateModal = () => dispatch(closeUpdateEventModalRequested())

    const dispatchError = () => dispatch(infosRequestFailed('Nicht Angemeldet'))
    const updateEvent = () => dispatchAction(token, token => dispatch(updatedEventRequested(event, token)), dispatchError)

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            setEvent(state.modals.updateEventModal.event)
            setShowUpdateModal(state.modals.updateEventModal.opened)
        })
    }, [store])

    return event
        ? <Modal show={showUpdateModal} onHide={closeUpdateModal}
                 centered={true} className="create-event-modal">
            <Modal.Header closeButton>
                <Modal.Title>Kurs bearbeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={2}>Titel</Col>
                        <Col md={10}>
                            <input
                                value={event.title}
                                onChange={event => setEvent(prevState => ({...prevState,  title: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Untertitel</Col>
                        <Col md={10}>
                            <input
                                value={event.subtitle}
                                onChange={event => setEvent(prevState => ({...prevState, subtitle: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Wann</Col>
                        <Col md={10}>
                            <textarea
                                value={event.when}
                                onChange={event => setEvent(prevState => ({...prevState, when: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Wo</Col>
                        <Col md={10}>
                            <textarea
                                value={event.where}
                                onChange={event => setEvent(prevState => ({...prevState, where: event.target.value}))}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Kategorie</Col>
                        <Col md={10}>
                            <Selection
                                items={Object.values(EventCategory).map(category => ({
                                    label: category,
                                    value: category
                                }))}
                                selectedItem={{label: event.category, value: event.category}}
                                setSelectedItem={item => {
                                    if (EventCategory.hasOwnProperty(item.value)) {
                                        setEvent(prevState => ({...prevState, category: item.value as EventCategory}))
                                    }
                                }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Buchungsstatus</Col>
                        <Col md={10}>
                            <Selection
                                items={Object.values(EventBookingStatus).map(status => ({label: EventBookingStatusMapping[status], value: status}))}
                                selectedItem={{label: EventBookingStatusMapping[event.status], value: event.status}}
                                setSelectedItem={item => {
                                    if (EventBookingStatus.hasOwnProperty(item.value)) {
                                        setEvent(prevState => ({...prevState, status: item.value as EventBookingStatus}))
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeUpdateModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={() => {
                    updateEvent()
                    closeUpdateModal()
                }}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
        : <></>
}

export default UpdateEventModal