import {FileType} from "../../../api/files";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {faMusic} from "@fortawesome/free-solid-svg-icons/faMusic";

export const getFileSymbol = (resourceType: FileType) => {
    switch (resourceType) {
        case FileType.DOCUMENT:
            return <FontAwesomeIcon icon={faFileAlt} focusable={true} className="resource-type-icon"/>
        case FileType.RECORDING:
            return <FontAwesomeIcon icon={faMusic} focusable={true} className="resource-type-icon"/>
    }
}

export const removeTags = (text: string) => {
    let cleanedString = ""
    let htmlTag = false

    for (let letter of text) {
        if (letter === '<') {
            htmlTag = true
        } else if (letter === '>') {
            htmlTag = false
        } else if (!htmlTag) {
            cleanedString += letter
        }
    }

    return cleanedString
}

export const askBefore = (message: string, callback: () => any) => {
    if (window.confirm(message)) {
        callback()
    }
}

export const getFileIcon = (resourceType: FileType) => {
    switch (resourceType) {
        case FileType.DOCUMENT:
            return "📄"
        case FileType.RECORDING:
            return "🎵"
        default:
            return ""
    }
}