import {dispatchAction} from "../../common";
import {addReferenceRequested} from "../../../store/index-content/actions";
import React, {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import {closeAddReferenceModalRequested} from "../../../store/modals/actions";
import {eventsRequestFailed} from "../../../store/events/action";
import {useSetTokenEffect} from "../../../pages/content/hooks";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {CreateReferenceInput, ReferenceItem} from "../../../api/references";


const AddReferenceModal: React.FC = () => {
    const store = useStore()
    const dispatch = useDispatch()

    const initialInputData: CreateReferenceInput = {cite: "", author: "", position: 0}

    const [token, setToken] = useState<string>()
    const [inputData, setInputData] = useState<CreateReferenceInput>(initialInputData)
    const [references, setReferences] = useState<ReferenceItem[]>(store.getState().references.data)
    const [showModal, setShowModal] = useState(store.getState().modals.addReferenceModal)

    const closeModal = () => {
        setInputData(initialInputData)
        dispatch(closeAddReferenceModalRequested())
    }

    const dispatchError = () => dispatch(eventsRequestFailed('Nicht Angemeldet'))
    const addReference = () => {
        dispatchAction(token, token => dispatch(addReferenceRequested({...inputData, position: references.length}, token)), dispatchError)
    }

    useSetTokenEffect(store, setToken)
    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            setReferences(state.references.data)
            setShowModal(state.modals.addReferenceModal)
        })
    }, [dispatch, store, token])

    return <Modal show={showModal} onHide={closeModal}
                  centered={true} className="create-event-modal">
        <Modal.Header closeButton>
            <Modal.Title>Referenz hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Container>
                    <Row>
                        <Col md={2}>Zitat</Col>
                        <Col md={10}>
                            <input
                                value={inputData.cite}
                                onChange={event => setInputData(prevState => ({...prevState, cite: event.target.value}))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>Autor</Col>
                        <Col md={10}>
                            <input
                                value={inputData.author}
                                onChange={event => setInputData(prevState => ({...prevState, author: event.target.value}))}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Schließen
            </Button>
            <Button variant="primary" onClick={() => {
                if (inputData.author === "" && inputData.cite === "")
                    return alert("Felder nicht vollständig ausgefüllt")
                addReference()
                closeModal()
            }}>
                Hinzufügen
            </Button>
        </Modal.Footer>
    </Modal>
}

export default AddReferenceModal